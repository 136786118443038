import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore'; // needed for firestore
import { combineReducers } from 'redux';
import { assessmentData, userData } from './reducer';

export default combineReducers({
  assessmentData,
  userData,
  firebase: firebaseReducer,
  firestore: firestoreReducer
});