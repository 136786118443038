import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useNavigate } from '@reach/router';
import TextField from '@material-ui/core/TextField';
import Message from '../../Common/Message';
import Loading from '../../Common/Loading';
import Button from '../../Common/Button';
import { ROUTES } from '../../../util/routes';
import {
  ButtonWrapper,
  Container,
  Element,
  EmailSentMessage,
  Header,
  LinkStyle,
  SubText
} from '../account.styles';

export const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [displayLoading, setDisplayLoading] = useState(false);

  const firebase = useFirebase();
  const navigate = useNavigate();

  const handleLoginClick = () => {
    setEmail('');
    setDisplayLoading(true);

    firebase.resetPassword(email).then(async resp => {
      setEmailSent(true);
      setTimeout(() => {
        setDisplayLoading(false);
        setEmailSent(false);
      }, 4000)
     
    }).catch(error => {
      setErrorMessage('Unable to reset password. Check that your email is correct');
      console.error('Error resetting password', error);
      setDisplayLoading(false);
    });
  };

  return ( 
    <Container>
      {errorMessage &&
        <Message text={errorMessage} handleClose={() => setEmail('') } /> 
      }
      <Header>Forgot Your Password?</Header>
      <SubText>Please enter your email to reset your password.</SubText>
      <Element>
        <TextField
          type="text"
          label="Email"
          placeholder="Enter your email"
          fullWidth
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Element>
      <ButtonWrapper>
        <Button disabled={!email} action={handleLoginClick} label="Reset My Password" />
      </ButtonWrapper>
      <LinkStyle onClick={() => navigate(ROUTES.login)}>Back to Sign In</LinkStyle>
      {emailSent && <EmailSentMessage>Check your email for the reset link!</EmailSentMessage>}
      {displayLoading && <Element> <Loading /> </Element>}
    </Container>
  );
};

export default PasswordReset;
