import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";
import { useNavigate } from "@reach/router";
import TextField from "@material-ui/core/TextField";
import Message from "../../Common/Message";
import Loading from "../../Common/Loading";
import Button from "../../Common/Button";
import { ROUTES } from "../../../util/routes";
import {
  ButtonWrapper,
  Container,
  Element,
  Header,
  FormContainer,
  LinkStyle,
  SubText,
} from "../account.styles";
export const LoginForm = ({ auth, profile }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [displayLoading, setDisplayLoading] = useState(false);
  const navigate = useNavigate();
  const firebase = useFirebase();

  const handleLoginClick = () => {
    setErrorMessage("");
    setDisplayLoading(true);

    firebase
      .login({ email, password })
      .then(async (resp) => {
        setDisplayLoading(false);
        setErrorMessage("");
        navigate(ROUTES.profile);
      })
      .catch((error) => {
        setErrorMessage("Invalid email/password combination");
        console.error("Error signing in", error);
        setDisplayLoading(false);
      });
  };

  return (
    <Container>
      <Header>Sign In</Header>
      <FormContainer>
        {errorMessage && (
          <Message
            text={errorMessage}
            handleClose={() => setErrorMessage("")}
          />
        )}
        <Element>
          <TextField
            type="text"
            label="Email"
            fullWidth
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Element>
        <Element>
          <TextField
            type="password"
            label="Password"
            fullWidth
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Element>
        <ButtonWrapper>
          <Button
            disabled={!email || !password}
            action={handleLoginClick}
            label="Sign In"
          />
        </ButtonWrapper>
        <LinkStyle onClick={() => navigate(ROUTES.passwordReset)}>
          I forgot my password
        </LinkStyle>
        <SubText>
          Don't have a S.M.I.L.E account?
          <LinkStyle onClick={() => navigate(ROUTES.register)}>
            {" "}
            Create one.
          </LinkStyle>
        </SubText>
        {displayLoading && (
          <Element>
            {" "}
            <Loading />{" "}
          </Element>
        )}
      </FormContainer>
    </Container>
  );
};

export default LoginForm;
