import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  color: #000;
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
`;

const Text = styled.div`
  color: #000;
`;

const Count = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-right: 8px;
`;

export { Container, Count, TextRow, Text };
