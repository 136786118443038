import styled from 'styled-components';

const PopupLoading = styled.div`
  border: 5px solid #f0a91d;
  border-top: 5px solid red;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: spin .8s linear infinite;
  margin: 0 auto;
`;

const PopupLoadingContainer = styled.div`
   margin-top: 1em;
  @-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}
`;

export { PopupLoading, PopupLoadingContainer };
