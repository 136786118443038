import { useState } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { generateAlphaSurveyCodes } from "../util/generateSurveyCodes";

const useGenerateAndInsertSurveyCodes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const firestore = useFirestore();
  const firebase = useFirebase();
  const [codesInserted, setCodesInserted] = useState([]);

  const insertCodes = async (numberOfCodes = 2) => {
    setIsLoading(true);
    const generatedCodes = generateAlphaSurveyCodes(numberOfCodes);
    const status = "INACTIVE";

    for (const code of generatedCodes) {
      await firestore.collection("surveyCodes").doc(code).set({
        code,
        status,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
    setCodesInserted(generatedCodes);
    setIsLoading(false);
  };

  return { isLoading, codesInserted, insertCodes };
};

export { useGenerateAndInsertSurveyCodes };
