import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 520px;
  margin: 12px 0;
  border: 1px dotted gray;
  border-radius: 10px;
  background-color: white;
`;

export const Description = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: black;
  text-align: justify;
`;

export const ListItem = styled.li`
  font-size: 18px;
  line-height: 22px;
  margin: 8px 0;
  color: black;
`;
