import React, { useEffect } from "react";
import { useNavigate } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import { isLoaded, isEmpty, useFirebase } from "react-redux-firebase";
import Button from "../../Common/Button";
import { ROUTES } from "../../../util/routes";
import { SIGN_OUT } from "../../../redux/actions/actions";
import {
  Section,
  ItemRow,
  Header,
  Label,
  ButtonWrapper,
  TextStyle,
} from "../account.styles";

export const Profile = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.firebase.auth);
  const user = useSelector((state) => state.firebase.profile);
  const dispatch = useDispatch();
  const firebase = useFirebase();

  const { displayName, email, organization, age, race, gender } = user;

  useEffect(() => {
    if (isLoaded(auth) && isEmpty(auth)) {
      navigate(ROUTES.root); // user not authenticated; peace out...
    }
  }, [auth, navigate]);

  const signOutAction = () => {
    dispatch({ type: SIGN_OUT });
    firebase.logout();
    navigate(ROUTES.root);
  };

  return (
    <Section>
      <Header>My Profile</Header>
      <ItemRow>
        <Label>Name:</Label>
        <TextStyle>{displayName}</TextStyle>
      </ItemRow>
      <ItemRow>
        <Label>Email:</Label>
        <TextStyle>{email}</TextStyle>
      </ItemRow>
      <ItemRow>
        <Label>Organization:</Label>
        <TextStyle>{organization || "N/A"}</TextStyle>
      </ItemRow>
      <ItemRow>
        <Label>Age:</Label>
        <TextStyle>{age}</TextStyle>
      </ItemRow>
      <ItemRow>
        <Label>Race:</Label>
        <TextStyle>{race}</TextStyle>
      </ItemRow>
      <ItemRow>
        <Label>Gender:</Label>
        <TextStyle>{gender}</TextStyle>
      </ItemRow>
      <ButtonWrapper>
        <Button label="Sign Out" action={signOutAction} />
      </ButtonWrapper>
    </Section>
  );
};

export default Profile;
