const fullAssessment = [
  {
    id: 1,
    name: "Belief/Attitude",
    description: "Individual, Professional & Societal Level",
    sortOrder: 1,
    createdAt: "2020-05-13 12:00:01",
    questions: [
      {
        id: 101,
        categoryId: 1,
        name: "I am aware of and sensitive to my own culture and heritage.",
        sortOrder: 1,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 102,
        categoryId: 1,
        name: "I am aware of my own background experiences and biases.",
        sortOrder: 2,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 103,
        categoryId: 1,
        name:
          "I am comfortable with differences between myself and other people.",
        sortOrder: 3,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 104,
        categoryId: 1,
        name:
          "I am conscious of my emotional reactions towards diverse racial/ethnic groups.",
        sortOrder: 4,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 105,
        categoryId: 1,
        name:
          "I am conscious of my emotional reactions towards the LGBTQIA+ population.",
        sortOrder: 5,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 106,
        categoryId: 1,
        name:
          "I am nonjudgmental towards  diverse racial/ethnic/gender groups.",
        sortOrder: 6,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 107,
        categoryId: 1,
        name:
          "I am aware of stereotypes and misperceptions towards diverse groups.",
        sortOrder: 7,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 108,
        categoryId: 1,
        name:
          "I respect different religious and spiritual beliefs and practices.",
        sortOrder: 8,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 109,
        categoryId: 1,
        name:
          "I respect a diverse array of cultural practices and community celebrations.",
        sortOrder: 9,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 110,
        categoryId: 1,
        name:
          "I am sensitive to challenges pertaining to gender identification.",
        sortOrder: 10,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 111,
        categoryId: 1,
        name: "I am sensitive to challenges pertaining to sexual orientation.",
        sortOrder: 11,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 112,
        categoryId: 1,
        name:
          "I am patient when dealing with speakers who have limited English proficiency.",
        sortOrder: 12,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 113,
        categoryId: 1,
        name: "I value and respect bilingualism.",
        sortOrder: 13,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 114,
        categoryId: 1,
        name:
          "I am open-minded regarding issues of power and politics pertaining to immigration.",
        sortOrder: 14,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 115,
        categoryId: 1,
        name: "I believe that White privilege exists within the U.S.",
        sortOrder: 15,
        createdAt: "2020-05-13 12:00:01",
      },
    ],
  },
  {
    id: 2,
    name: "Knowledge",
    description: "Individual, Professional & Societal Level",
    sortOrder: 2,
    createdAt: "2020-05-13 12:00:01",
    questions: [
      {
        id: 201,
        categoryId: 2,
        name:
          "I am aware of my own racial/cultural background and how it influences perceptions.",
        sortOrder: 1,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 202,
        categoryId: 2,
        name: "I recognize and acknowledge my own biases.",
        sortOrder: 2,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 203,
        categoryId: 2,
        name:
          "I am aware of influences that shape my attitudes, beliefs and perspectives.",
        sortOrder: 3,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 204,
        categoryId: 2,
        name: "I am aware of my own communication style.",
        sortOrder: 4,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 205,
        categoryId: 2,
        name:
          "I am knowledgeable about diverse groups I work and/or interact with.",
        sortOrder: 5,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 206,
        categoryId: 2,
        name:
          "I understand how race/ethnicity affects personal identity and self-esteem.",
        sortOrder: 6,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 207,
        categoryId: 2,
        name:
          "I understand how gender identity/sexual orientation affects personal identify and self-esteem.",
        sortOrder: 7,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 208,
        categoryId: 2,
        name:
          "I understand how race/ethnicity affects educational experiences and vocational choices.",
        sortOrder: 8,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 209,
        categoryId: 2,
        name:
          "I am aware of legal rights and policies pertaining to the LGBTQIA+ population.",
        sortOrder: 9,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 210,
        categoryId: 2,
        name:
          "I understand cultural and class factors that pertain to seeking psychological help.",
        sortOrder: 10,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 211,
        categoryId: 2,
        name:
          "I understand cultural, class and gender factors that impede medical care and treatment.",
        sortOrder: 11,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 212,
        categoryId: 2,
        name: "I am knowledgeable about diverse family structures.",
        sortOrder: 12,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 213,
        categoryId: 2,
        name: "I recognize and can describe or define white privilege.",
        sortOrder: 13,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 214,
        categoryId: 2,
        name:
          "I am aware of challenges LGBTQIA+ may face in work, religious and public settings.",
        sortOrder: 14,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 215,
        categoryId: 2,
        name:
          " I am aware of how discriminatory practices operate on an institutional level.",
        sortOrder: 15,
        createdAt: "2020-05-13 12:00:01",
      },
    ],
  },
  {
    id: 3,
    name: "Skill",
    description: "Individual, Professional & Societal Level",
    sortOrder: 3,
    createdAt: "2020-05-13 12:00:01",
    questions: [
      {
        id: 301,
        categoryId: 3,
        name:
          "I strive to understand myself from a racial and cultural perspective.",
        sortOrder: 1,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 302,
        categoryId: 3,
        name:
          "I engage in study (books, podcasts, video) to learn about diverse cultural experiences.",
        sortOrder: 2,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 303,
        categoryId: 3,
        name:
          "I interact with and maintain relationships with diverse people in the work setting.",
        sortOrder: 3,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 304,
        categoryId: 3,
        name:
          "I am involved with diverse groups (friends, associates) outside of my work role.",
        sortOrder: 4,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 305,
        categoryId: 3,
        name:
          "I am able to engage in a variety of verbal/nonverbal communication styles.",
        sortOrder: 5,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 306,
        categoryId: 3,
        name:
          "I am able to use technology to effectively and efficiently communicate.",
        sortOrder: 6,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 307,
        categoryId: 3,
        name:
          "I use technology to communicate with people on an international basis.",
        sortOrder: 7,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 308,
        categoryId: 3,
        name:
          "I actively engage in work to reduce and address prejudice and discrimination.",
        sortOrder: 8,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 309,
        categoryId: 3,
        name: "I am affiliated with diverse clubs and social groups.",
        sortOrder: 9,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 310,
        categoryId: 3,
        name:
          "I participate in programs that support a range of diverse cultures.",
        sortOrder: 10,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 311,
        categoryId: 3,
        name:
          "I maintain friendships and/or relationships with people who identify as LGBTQIA+",
        sortOrder: 11,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 312,
        categoryId: 3,
        name:
          "I maintain ongoing relationships with speakers from diverse linguistic groups.",
        sortOrder: 12,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 313,
        categoryId: 3,
        name: "I engage in international travel.",
        sortOrder: 13,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 314,
        categoryId: 3,
        name:
          "I actively seek to experience the lifestyle of “locals” while traveling.",
        sortOrder: 14,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 315,
        categoryId: 3,
        name: "I maintain international friendships and connections.",
        sortOrder: 15,
        createdAt: "2020-05-13 12:00:01",
      },
    ],
  },
  {
    id: 4,
    name: "Experience",
    description: "Individual, Professional  & Societal Level",
    sortOrder: 4,
    createdAt: "2020-05-13 12:00:01",
    questions: [
      {
        id: 401,
        categoryId: 4,
        name:
          "I have attended a bilingual or dual language immersion program or class.",
        sortOrder: 1,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 402,
        categoryId: 4,
        name:
          "I have participated in programs conducted in a  foreign language.",
        sortOrder: 2,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 403,
        categoryId: 4,
        name:
          "I have participated in multicultural programs over an extended period of time.",
        sortOrder: 3,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 404,
        categoryId: 4,
        name:
          "I have participated in activities with diverse colleagues or peers.",
        sortOrder: 4,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 405,
        categoryId: 4,
        name:
          "I have participated in activities with bilingual colleagues or peers.",
        sortOrder: 5,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 406,
        categoryId: 4,
        name:
          "I have participated in activities and/or group settings in which I was the minority.",
        sortOrder: 6,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 407,
        categoryId: 4,
        name:
          "I have engaged in travel with multicultural colleagues or peers.",
        sortOrder: 7,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 408,
        categoryId: 4,
        name: "I have engaged in travel with bilingual colleagues or peers.",
        sortOrder: 8,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 409,
        categoryId: 4,
        name:
          "I have engaged in close interactions with people who identify as LGBTQIA.+",
        sortOrder: 9,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 410,
        categoryId: 4,
        name:
          "I have engaged in close interactions across diverse racial, cultural and linguistic lines.",
        sortOrder: 10,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 411,
        categoryId: 4,
        name:
          "I have been exposed to informal conversations in English and another language.",
        sortOrder: 11,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 412,
        categoryId: 4,
        name:
          "I have been exposed to formal instruction in English and another language.",
        sortOrder: 12,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 413,
        categoryId: 4,
        name: "I have basic command of a language other than English.",
        sortOrder: 13,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 414,
        categoryId: 4,
        name:
          "I am fully bilingual (proficient in speaking, reading and writing in two languages).",
        sortOrder: 14,
        createdAt: "2020-05-13 12:00:01",
      },
      {
        id: 415,
        categoryId: 4,
        name:
          "I frequently interact and engage with others in a language other than English.",
        sortOrder: 15,
        createdAt: "2020-05-13 12:00:01",
      },
    ],
  },
];

export default fullAssessment;
