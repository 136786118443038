import { useFirestore } from "react-redux-firebase";
import { useCallback, useEffect, useState } from "react";

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "city",
    label: "City",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "state",
    label: "State",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Organization Name",
    options: {
      filter: true,
      sort: true,
    },
  },
];

const options = {
  filterType: "checkbox",
  selectableRowsHeader: false,
  selectableRows: "none",
};

const useGetOrganizations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const firestore = useFirestore();

  const getOrganizationsList = useCallback(() => {
    setIsLoading(true);

    const organizationsRef = firestore.collection("organizations");
    organizationsRef
      .get()
      .then((data) => {
        const list = data.docs.map((doc) => ({
          id: doc.id,
          city: doc.data().city,
          name: doc.data().name,
          state: doc.data().state,
        }));
        setData(list);
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [firestore]);

  useEffect(() => {
    getOrganizationsList();
  }, [getOrganizationsList]);

  return { isLoading, error, data, options, columns };
};

export { useGetOrganizations };
