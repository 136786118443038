import React from 'react';
import Logo from './Logo';
import MenuBar from './MenuBar';
import { ROUTES } from '../../util/routes';
import { Container, TopRow } from './header.styles';

const Header = () => (
  <Container>
    <TopRow>
      <MenuBar />
    </TopRow>
      <div onClick={() => window.location = ROUTES.root}>
        <Logo />
      </div>
  </Container>
  );

export default Header;
