import React from 'react';
import {
  Container,
  TextBox,
  Text,
  Title
} from './aboutSurvey.styles';

const AboutSurveyRow = () => (
  <Container>

    <TextBox>
      <Title>What Is It?</Title>
      <Text>
      The Survey of Multicultural Interactions and Linguistic Engagement (SMILE) is an online, self-assessment tool rooted in H. Tinsley’s research, 
      based on the Multicultural Interactive Competency Framework (MICF) and sociocultural-historical theory.</Text>
      <Text>It is designed to gauge your level of 
      cultural competence, defined as having the necessary beliefs, attitudes, knowledge, skills and experiences to effectively interact with diverse 
      groups on personal, professional and social levels in different contexts.  These categories are somewhat fluid and always evolving and take into 
      consideration the differences within each group that exist based on race, culture, language, gender identification, and sexual orientation. 
      </Text>
      <Text>An explanation of terms is provided as needed to give clarity regarding their usage throughout the survey.
      </Text>
    </TextBox>

    <TextBox>
      <Title>Purpose</Title>

      <Text>
      The tool is a catalyst for self-reflection and can be used as a springboard to further one’s multicultural growth and development 
      and promote more effective communication, collaboration and team building skills within groups, schools and organizations.</Text>
      <Text>As a qualitative tool your score is a reflection of only you and it can be used as a progress-monitoring tool to assess your growth over time, 
       by taking an initial self-assessment to get a baseline rating, and follow-up assessments to gauge change or growth after participation in culturally relevant classes, 
       workshops and activities. </Text>
       <Text>The SMILE provides a starting point for honest and open discussions that unpack experiences that have shaped our views and perceptions pertaining 
       to race, culture, diversity, equity and inclusion across all populations. 
       A multicultural book list is included of select, diverse literature for individual or group use to develop a greater understanding of diverse cultures and experiences. 
      </Text>
    </TextBox>
   
    <TextBox>
      <Title>Scoring</Title>
      <Text>
      The 60 item survey consists of 4 components with 15 items each, formatted along a 5-point continuum, 
      with  “1= never, “2” = rarely, “3” = sometimes, “4” = often and “5” = always. 
      Higher scores are suggested to reflect greater levels of cultural competence. </Text>
      <Text>The SMILE suggests your level of cultural competence along a continuum ranging from poor, minimal, satisfactory to strong. 
      The SMILE provides individualized data with an indication of areas of strength and need pertaining to cultural competence in the four component areas: 
      attitudes and beliefs, knowledge, skills and experience. </Text>
      <Text>This tool does not predict your success or failure in relationships and interactions with diverse populations, nor does it reflect your commitment to educational change. 
      Instead it provides a means to enhance your personal growth in the area of cultural competence. 
      </Text>
    </TextBox>
      
  </Container>
  );

export default AboutSurveyRow;
