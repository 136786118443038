import styled from 'styled-components';

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  margin-left: 24px;
`;

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
`;

const ScoreText = styled.p`
  color: black;
  font-size: 14px;
  margin: 0;
`;

const ScoreValue = styled.p`
  color: black;
  margin-left: 10px;
`;

const ScoreInput = styled.input`
  margin-left: 8px;
`;

export { Container, ScoreInput, ScoreWrapper, ScoreText, ScoreValue };
