import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import ReduxPromiseMiddleware from 'redux-promise-middleware';
import rootReducer from './reducers/';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import { createFirestoreInstance } from 'redux-firestore';
import scores from '../assessmentData/scores';

const initialState = {};
const enhancers = [];
const middleware = [thunk, ReduxPromiseMiddleware()];

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: 'assessmentData'
};

const firebaseConfig = {
  apiKey: "AIzaSyDS7KRS0VP8CYti8Kn1WBabUOVhrkg1Fj0",
  authDomain: "smile-2ce92.firebaseapp.com",
  databaseURL: "https://smile-2ce92.firebaseio.com",
  projectId: "smile-2ce92",
  storageBucket: "smile-2ce92.appspot.com",
  messagingSenderId: "685909350490",
  appId: "1:685909350490:web:03f351cc013f74c236a063"
};

firebase.initializeApp(firebaseConfig);

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(composedEnhancers)
);

const storyBookInitialState = { assessmentData: { scores } };
export const storyBookStore = createStore(persistedReducer, storyBookInitialState);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

export const persistor = persistStore(store);