import React from 'react';
import PropTypes from 'prop-types';
import Score from '../Score';
import { Container, Count, Text, TextRow } from './question.styles';

export const Question = ({ question, count, handleAnswer }) => {
  const { id, name } = question;

  return (
    <Container>
      <TextRow>
        <Count>{count})</Count>
        <Text dangerouslySetInnerHTML={{ __html: name }}/>
      </TextRow>
      <Score onChange={handleAnswer} questionId={id} />
    </Container>
  )
}

Question.propTypes = {
  question: PropTypes.shape({}).isRequired,
  count: PropTypes.number.isRequired,
  handleAnswer: PropTypes.func.isRequired
}

export default Question;