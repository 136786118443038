const generateAlphaNumericSurveyCodes = (length) => {
  let codesArray = [];
  for (let i = 0; i < length; i++) {
    const code = Math.random().toString(20).substring(2, 8).toUpperCase();
    codesArray.push(code);
  }
  return codesArray;
};

const getRandomValueFromAlphabet = () => {
  const characterArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const min = 0;
  const max = characterArray.length;

  /*
   Math.random() will return a random number [0, 1) Notice here that it does not include 1 itself
   So basically it is from 0 to .9999999999999999

   We multiply this random number by the difference between max and min (max - min). Here our min is always 0.
   so now we are basically getting a value from 0 to just less than array.length
   BUT we then call Math.floor on this function which returns the given number rounded down to the nearest integer
   So Math.floor(Math.random() * (max - min)) returns 0 to array.length - 1
   This gives us a random index in the array
  */
  const randomIndex = Math.floor(Math.random() * (max - min)) + min;

  // then we grab the item that is located at that random index and return it
  return characterArray[randomIndex];
};
const getRandomString = (lengthOfString) => {
  let result = "";
  for (let i = 0; i <= lengthOfString; i++) {
    result += getRandomValueFromAlphabet();
  }
  return result;
};

const generateAlphaSurveyCodes = (numberOfCodes) => {
  let results = [];

  for (let i = 0; i < numberOfCodes; i++) {
    const code = getRandomString(6);
    results.push(code);
  }

  return results;
};

export { generateAlphaSurveyCodes, generateAlphaNumericSurveyCodes };
