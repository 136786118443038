import React from 'react';
import { PopupLoadingContainer, PopupLoading } from './Loading.styles';

const Loading = props => (
  <PopupLoadingContainer>
    <PopupLoading />
  </PopupLoadingContainer>
);

export default Loading;
