import React from "react";
import { Router } from "@reach/router";
import Home from "../Home";
import Header from "../Header";
import Assessment from "../Assessment";
import Profile from "../Account";
import LoginForm from "../Account/Login";
import ContactForm from "../ContactForm";
import PasswordReset from "../Account/PasswordReset";
import Products from "../Products";
import RecommendationPage from "../RecommendationPage";
import Register from "../Account/Register";
import { ROUTES } from "../../util/routes";
import { Container, Content } from "./main.styles";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const PAYPAL_CLIENT_ID = {
  SANDBOX:
    "AYnANEdyMVY9JVi8Ja9vpTh71Sq3r8nHMbKwNAprfH9fsvMR4iK2B3uKDWMKLDxOjBg4AMjZpA28593g",
  LIVE: "ATg2LR2g8IpKHf6eJJTaEde2m4a_p0JkZ39NmzUHalJdy7XbbBlA0nSVIj6-lAVt14-OKGecLEMZTsHZ",
};

export const Main = () => {
  return (
    <Container>
      <PayPalScriptProvider
        options={{
          "client-id":
            process.env.NODE_ENV === "development"
              ? PAYPAL_CLIENT_ID.SANDBOX
              : PAYPAL_CLIENT_ID.LIVE,
          components: "buttons",
          currency: "USD",
        }}
      >
        <Header />
        <Content>
          <Router>
            <Home path={ROUTES.root} default />
            <Assessment path={ROUTES.assessment} />
            <RecommendationPage path={ROUTES.recommendation} />
            <LoginForm path={ROUTES.login} />
            <ContactForm path={ROUTES.contact} />
            <Register path={ROUTES.register} />
            <PasswordReset path={ROUTES.passwordReset} />
            <Profile path={ROUTES.profile} />
            <Products path={ROUTES.products} />
          </Router>
        </Content>
      </PayPalScriptProvider>
    </Container>
  );
};

export default Main;
