import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Description, ListItem } from "./explanationOfTerms.styles";
import { colors } from "../../../util/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  acccordionSummary: {
    backgroundColor: colors.logo.yellow,
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const ExplanationOfTerms = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          className={classes.acccordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Explanation Of Terms
          </Typography>
          <Typography className={classes.secondaryHeading}>
            Click to expand and read terms
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Description>
            There are several key terms that must be defined in the context of
            this particular assessment pertaining to race, ethnicity, culture,
            sexual orientation and gender identification. These terms are
            italicized in the survey tool. This tool utilizes the U.S. Census
            Bureau’s definition of race, which reflects a social definition of
            race for data collection purposes (U.S. Census Bureau, 2013). The
            Census Bureau defines race as a person&#39;s self-identification
            with one or more social groups. An individual can report as White,
            Black or African American, Asian, American Indian and Alaska Native,
            Native Hawaiian and Other Pacific Islander, or some other race.
            Additionally, beginning with the 2010 Census, The U.S. Office of
            Management and Budget (OMB) mandate that race and Hispanic origin
            (ethnicity) be identified as distinctly separate concepts (Humes,
            Jones &amp; Ramirez, 2011; Shrestha &amp; Heisler, 2006).
            <ul>
              <ListItem>
                <b>White</b> – A person having origins in any of the original
                peoples of Europe, the Middle East, or North Africa
              </ListItem>
              <ListItem>
                <b>Black or African American</b> – A person having origins in
                any of the Black racial groups of Africa
              </ListItem>
              <ListItem>
                <b>American Indian or Alaska Native</b> – A person having
                origins in any of the original peoples of North and South
                America (including Central America) and who maintains tribal
                affiliation or community attachment
              </ListItem>
              <ListItem>
                <b>Asian</b> – A person having origins in any of the original
                peoples of the Far East, Southeast Asia, or the Indian
                subcontinent including, (Cambodia, China, India, Japan, Korea,
                Malaysia, Pakistan, the Philippine Islands, Thailand, and
                Vietnam)
              </ListItem>
              <ListItem>
                <b>Native Hawaiian or Other Pacific Islander</b> – A person
                having origins in any of the original peoples of Hawaii, Guam,
                Samoa, or other Pacific Islands
              </ListItem>
              <ListItem>
                <b>Hispanic</b> – A person of Cuban, Mexican, Puerto Rican,
                South or Central American, or other Spanish culture, exclusive
                of race
              </ListItem>
              <ListItem>
                <b>Bilingual</b> – refers to students who have fluent command
                and understanding of two languages
              </ListItem>
              <ListItem>
                <b>Communication style</b> – These are the broad ways in which
                people tend to communicate with from others. There are four
                basic styles of communication: passive, aggressive,
                passive-aggressive, and assertive. Each of these styles
                describes the different ways people communicate and learn.
                <ul>
                  <ListItem>
                    Passive Communication: Not expressing feelings or needs;
                    ignoring your own personal rights and allowing others to do
                    so
                  </ListItem>
                  <ListItem>
                    Aggressive Communication: Expressing feelings, needs, and
                    ideas at the expense of others; ignoring others’ rights in
                    order to support your own
                  </ListItem>
                  <ListItem>
                    Passive-Aggressive Communication: Appearing passive on the
                    surface, but subtly acting out anger, exerting control over
                    others by using sarcasm and indirect communication, or
                    avoiding the conversation
                  </ListItem>
                  <ListItem>
                    Assertive Communication: Direct, honest communication of
                    thoughts and feelings, Respecting the feelings, ideas, and
                    needs of others while also asserting your own
                  </ListItem>
                </ul>
              </ListItem>
              <ListItem>
                <b>Culture</b> – is the characteristics, knowledge, customary
                beliefs, social forms and material traits of a particular group
                of people, encompassing race, language, religion, cuisine,
                social habits, music and arts.
              </ListItem>
              <ListItem>
                <b>Cultural background</b> – comprises the ethnic, religious,
                racial, gender, linguistic or other socioeconomic factors and
                values that shape an individual’s upbringing.
              </ListItem>
              <ListItem>
                <b>Cultural competence</b> – The ability to “navigate cultural
                contexts smoothly by strategically and flexibly displaying
                appropriate behaviors to pursue valued goals in different
                cultural contexts” (Leung et al., 2013, p. 993).
              </ListItem>
              <ListItem>
                <b>Diverse family structures</b> -– This refers to a variety of
                family groups: traditional nuclear family (mother, father,
                child), single parent, same-sex parents, grandparents, extended
                family, foster parents, non-custodial parents
              </ListItem>
              <ListItem>
                <b>Diverse racial/ethnic groups</b> – This refers to various
                groups based on race, ethnicity, heritage and national origin
              </ListItem>
              <ListItem>
                <b>English language learner</b> – English language learner (ELL)
                refers to students whose native language is other than English,
                and they are learning English as a second language
              </ListItem>
              <ListItem>
                <b>Heritage</b> – practices or characteristics that are passed
                down through the years, from one generation to the next.
              </ListItem>
              <ListItem>
                <b>Latinx</b> – A gender-neutral term, sometimes used instead of
                Latino or Latina to refer to people of Latin American cultural
                or ethnic identity in the United States.
              </ListItem>
              <ListItem>
                <b>Limited English Proficiency</b> – This refers to individuals
                who have not developed oral and/or written proficiency in
                English.
              </ListItem>
              <ListItem>
                <b>Marginalization</b> – This is the act of being excluded,
                ignored and/or relegated as an outsider of a group, community or
                society.
              </ListItem>

              <ListItem>
                <b>Religious and spiritual beliefs and practices</b> – This
                refers to both common and uncommon religions, indigenous
                practices, traditional beliefs, agnostics (questioners) and
                atheist (nonbelievers)
              </ListItem>
              <ListItem>
                <b>Verbal communication</b> – This refers to the use of auditory
                language to exchange information with other people, and includes
                oral language (sounds, words and speech) and written language
                (letters, emails, texts, emojis)
              </ListItem>
              <ListItem>
                <b>Non-verbal communication</b> – This refers to communication
                without the use of spoken language and includes: gestures,
                facial expressions, body language, and types of touch (hand
                shaking, hugging, bowing, etc.)
              </ListItem>
              <ListItem>
                <b>White Privilege</b> – This refers to the inherent rights,
                advantages and exemptions from certain burdens granted to and/or
                enjoyed by White persons on the basis of their race that are not
                afforded to people of color, in a society characterized by
                racial inequality and injustice.
              </ListItem>
            </ul>
            Several of the terms and definitions pertaining to sexual
            orientation and gender identification were based on the University
            of California’s LGBTQIA+ Resource Center Glossary. (Lesbian, Gay,
            Bisexual, Transgender, Queer, Intersex, Asexual Resource Center,
            2019). For more comprehensive list of LGBTQIA+ terms and
            definitions: https://lgbtqia.ucdavis.edu/educated/glossary
            <ul>
              <ListItem>
                <b>Asexual</b> – A person who is not sexually attracted to other
                people
              </ListItem>
              <ListItem>
                <b>Bisexual</b> – A person who is attracted to two sexes or two
                genders, yet not necessarily simultaneously or equally
              </ListItem>
              <ListItem>
                <b>Cisgender</b> – A person whose sense of personal identity and
                gender corresponds with their birth or biological sex
              </ListItem>
              <ListItem>
                <b>Gay</b> – Men that are attracted emotionally, romantically,
                and/or sexually to men, and this is often used as a generic or
                umbrella term to include all LGBTQIA+ people
              </ListItem>
              <ListItem>
                <b>Gender</b> – A socially constructed system of classification
                that attributes qualities of masculinity and femininity to
                people. Gender characteristics can change over time and are
                different between cultures. Gender can be used to describe
                one&#39;s sense of self as masculine or feminine regardless of
                external genitalia.
              </ListItem>
              <ListItem>
                <b>Gender Fluid</b> – A person who does not identify with having
                a fixed gender
              </ListItem>

              <ListItem>
                <b>Heterosexism</b> – The assumption that all people are or
                should be heterosexual. It is often a subtle form of oppression,
                which reinforces realities of silence and erasure.
              </ListItem>
              <ListItem>
                <b>Homophobia</b> – A dislike of or prejudice against homosexual
                people
              </ListItem>
              <ListItem>
                <b>Intersex</b> – A person whose sex chromosomes, physical
                characteristics, external genitalia, or internal reproductive
                system is not categorized as non-standard for either male or
                female, or not exclusively male or exclusively female.
              </ListItem>
              <ListItem>
                <b>Lesbian</b> – A woman who is emotionally, romantically, or
                sexually attracted to other women
              </ListItem>
              <ListItem>
                <b>LGBTQIA+</b> – A person who identifies as Lesbian, Gay,
                Bisexual, Transgender, Queer, Intersex or Asexual. The +
                indicates that the acronyms people use are evolving and
                changing.
              </ListItem>
              <ListItem>
                <b>Nonbinary</b> – A gender identity and experience that
                embraces a full range of expressions and ways of being that
                resonate for an individual, moving beyond the male/female gender
                binary.
              </ListItem>
              <ListItem>
                <b>Queer</b> – An umbrella term that refers to all LGBTQIA+
                people
              </ListItem>
              <ListItem>
                <b>Pansexual</b> – A term used to describe people who have
                romantic, sexual or affectional desire for people of all genders
                and sexes. Has some overlap with bisexuality and polysexuality.
              </ListItem>
              <ListItem>
                <b>Sexual Orientation</b> – A term used to describe an
                emotional, romantic, sexual or affectional attraction or
                non-attraction to other people. Sexual orientation can be fluid
                and people use a variety of labels to describe their sexual
                orientation.
              </ListItem>
              <ListItem>
                <b>Transgender</b> – A person whose gender identity differs from
                the biological sex they were born with.
              </ListItem>
            </ul>
          </Description>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ExplanationOfTerms;
