import React from 'react';
import PropTypes from 'prop-types';
import { Container, ScoreWrapper, ScoreText, ScoreInput } from './score.styles';
import scores from '../../../assessmentData/scores';

export const Score = ({ onChange, questionId }) => {  
  return (
    <Container>
      {scores.map(score => (
        <ScoreWrapper key={score.id}>
         <ScoreText>{score.name}</ScoreText>
          <ScoreInput 
            type="radio" 
            id={score.id}
            name={`question${questionId}`}
            value={score.value}
            onChange={() => onChange({ questionId, score: score.value} )}
          />
        </ScoreWrapper>
      ))}
    </Container>
  )
}

Score.propTypes = {
  onChange: PropTypes.func.isRequired,
  questionId: PropTypes.number.isRequired,
}

export default Score;