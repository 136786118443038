import React, { useState, useEffect } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";
import TextField from "@material-ui/core/TextField";
import DropDown from "../../Common/DropDown";
import Button from "../../Common/Button";
import Message from "../../Common/Message";
import Loading from "../../Common/Loading";
import { RACES, GENDERS, AGE_RANGES } from "../../../util/constants";
import { ROUTES } from "../../../util/routes";
import { useNavigate } from "@reach/router";
import {
  Container,
  FormContainer,
  Element,
  Header,
  SubText,
  LinkStyle,
  PasswordHelp,
  ButtonWrapper,
} from "../account.styles";
import { useCallback } from "react";

export const Register = () => {
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [organization, setOrganization] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [displayLoading, setDisplayLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);

  const firebase = useFirebase();
  const firestore = useFirestore();
  const navigate = useNavigate();

  const isButtonDisabled =
    !email.trim() ||
    !displayName ||
    !password ||
    !confirmPassword ||
    password !== confirmPassword;

  const getListOfOrganizations = useCallback(async () => {
    const organizationsRef = firestore.collection("organizations");
    const snapshot = await organizationsRef.get();
    const organizationsList = snapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
    }));

    setOrganizations(organizationsList);
  }, [firestore]);

  useEffect(() => {
    getListOfOrganizations();
  }, [getListOfOrganizations]);

  useEffect(() => {
    const mismatchedPasswords =
      password.length && confirmPassword.length && password !== confirmPassword;
    setErrorMessage(mismatchedPasswords ? "Passwords must match!" : "");
  }, [password, confirmPassword]);
  useEffect(() => {
    const invalidLength =
      password.length &&
      confirmPassword.length &&
      (password.length < 6 || confirmPassword.length < 6);
    setErrorMessage(
      invalidLength ? "Password must be at least 6 characters!" : ""
    );
  }, [password, confirmPassword]);

  const clearErrors = () => {
    setErrorMessage("");
  };

  const registerAction = async () => {
    setDisplayLoading(true);

    clearErrors();

    const trimmedEmail = email.trim();

    try {
      const profileData = {
        displayName,
        email: trimmedEmail,
        organization,
        organizationId:
          organization && !!organizations.length
            ? organizations.find((x) => x.name === organization).id
            : "",
        age,
        race,
        gender,
      };
      await firebase.createUser({ email: trimmedEmail, password }, profileData);
      window.location.href = ROUTES.profile;
    } catch (error) {
      setDisplayLoading(false);
      console.log("Error on registerAction", error);
      setErrorMessage(error.message);
    }
  };

  return (
    <Container>
      <Header>Create Your S.M.I.L.E Account</Header>
      <SubText>
        Already Have a S.M.I.L.E account?
        <LinkStyle onClick={() => navigate(ROUTES.login)}> Sign in</LinkStyle>
      </SubText>

      <FormContainer>
        {errorMessage && (
          <Message text={errorMessage} handleClose={clearErrors} />
        )}
        <Element>
          <TextField
            type="text"
            label="Email *"
            fullWidth
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Element>
        <Element>
          <TextField
            type="password"
            label="Password *"
            fullWidth
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Element>
        <Element>
          <TextField
            type="password"
            label="Confirm Password *"
            fullWidth
            variant="outlined"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <PasswordHelp>
            Password must be a minimum of 8 characters, contain a letter AND a
            number OR special character.
          </PasswordHelp>
        </Element>
        <Element>
          <TextField
            type="text"
            label="Full Name *"
            fullWidth
            variant="outlined"
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </Element>
        <Element>
          <DropDown
            label="School/Organization"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            data={organizations.map((o) => o.name)}
          />
        </Element>
        <Element>
          <DropDown
            label="Age Range"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            data={AGE_RANGES}
          />
        </Element>
        <Element>
          <DropDown
            label="Race/Ethnicity"
            value={race}
            onChange={(e) => setRace(e.target.value)}
            data={RACES}
          />
        </Element>
        <Element>
          <DropDown
            label="Gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            data={GENDERS}
          />
        </Element>
        <ButtonWrapper>
          <Button
            label="Create My Account"
            disabled={isButtonDisabled}
            action={registerAction}
          />
        </ButtonWrapper>
        {displayLoading && (
          <Element>
            {" "}
            <Loading />{" "}
          </Element>
        )}
      </FormContainer>
    </Container>
  );
};

export default Register;
