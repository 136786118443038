const scores = [
  {
    id: 1,
    name: "Never",
    value: 1,
    sortOrder: 1,
    createdAt: "2020-05-13 12:00:01",
  },
  {
    id: 2,
    name: "Rarely",
    value: 2,
    sortOrder: 2,
    createdAt: "2020-05-13 12:00:01",
  },
  {
    id: 3,
    name: "Sometimes",
    value: 3,
    sortOrder: 3,
    createdAt: "2020-05-13 12:00:01",
  },
  {
    id: 4,
    name: "Often",
    value: 4,
    sortOrder: 4,
    createdAt: "2020-05-13 12:00:01",
  },
  {
    id: 5,
    name: "Always",
    value: 5,
    sortOrder: 5,
    createdAt: "2020-05-13 12:00:01",
  },
];

export default scores;
