import styled from "styled-components";

const AdminContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-bottom: 8px;
  border: 1px solid black;
`;

const AdminMenuItem = styled.div`
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.isSelected === "true" ? "#eb9f23" : "#000")};
  cursor: pointer;
  margin: 0 16px;
  text-decoration: ${(props) =>
    props.isSelected === "true" ? "underline" : "none"};

  :hover {
    color: #eb9f23;
    text-decoration: underline;
  }
`;

const AdminTable = styled.div`
  display: table;
  width: 100%;
  border: 1px dotted black;
`;
const AdminTableHeaderRow = styled.div`
  display: table-header-group;
`;
const AdminTableHeaderCell = styled.div`
  display: table-cell;
  background-color: gray;
  color: white;
  font-size: 13px;
  font-weight: 600;
  text-align: justify;
  padding: 10px;
`;
const AdminTableBody = styled.div`
  display: table-row-group;
`;
const AdminTableRow = styled.div`
  display: table-row;
`;

const AdminTableBodyCell = styled.div`
  display: table-cell;
  font-size: 14px;
  color: black;
  border: black dotted black;
  padding: 8px 0;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
  margin: 0 12px;
`;

const DropdownWrapper = styled.div`
  margin: 0 12px;
  width: 250px;
`;

const Count = styled.p`
  margin-left: 12px;
`;

export {
  AdminContainer,
  AdminMenuItem,
  AdminTable,
  AdminTableHeaderCell,
  AdminTableHeaderRow,
  AdminTableBody,
  AdminTableRow,
  AdminTableBodyCell,
  ButtonRow,
  Count,
  DropdownWrapper,
};
