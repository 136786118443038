import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './button.styles';

export const Button = ({ alt, label, action, disabled }) => {
  return (
    <StyledButton 
      type="button"
      value={label}
      disabled={disabled} 
      onClick={action}
      alt={alt}
    /> 
  )
}
Button.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  alt: PropTypes.string
};

Button.defaultProps = {
  disabled: false,
  alt: 'false',
};

export default Button;