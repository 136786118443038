import MUIDataTable from "mui-datatables";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import React from "react";
import { useGetCompletedSurveys } from "../../../hooks/useGetCompletedSurveys";
import Loading from "../Loading";
import { useCallback } from "react";

const CompletedSurveysSection = () => {
  const { data, isLoading, columns } = useGetCompletedSurveys();

  const customTableBodyFooterRender = useCallback((bodyFooterOptions) => {
    const totalBelief = bodyFooterOptions.data.reduce((acc, item) => {
      return acc + item.data[4];
    }, 0);

    const totalKnowledge = bodyFooterOptions.data.reduce((acc, item) => {
      return acc + item.data[5];
    }, 0);
    const totalSkill = bodyFooterOptions.data.reduce((acc, item) => {
      return acc + item.data[6];
    }, 0);
    const totalExperience = bodyFooterOptions.data.reduce((acc, item) => {
      return acc + item.data[7];
    }, 0);
    const totalTotalScore = bodyFooterOptions.data.reduce((acc, item) => {
      return acc + item.data[8];
    }, 0);

    return (
      <TableFooter>
        <TableRow>
          {bodyFooterOptions.columns.map((col, index) => {
            if (col.display === "true") {
              if (col.name === "belief") {
                return <TableCell key={index}>Belief: {totalBelief}</TableCell>;
              } else if (col.name === "knowledge") {
                return (
                  <TableCell key={index}>Knowledge: {totalKnowledge}</TableCell>
                );
              } else if (col.name === "skill") {
                return <TableCell key={index}>Skill: {totalSkill}</TableCell>;
              } else if (col.name === "experience") {
                return (
                  <TableCell key={index}>
                    Experience: {totalExperience}
                  </TableCell>
                );
              } else if (col.name === "totalScore") {
                return (
                  <TableCell key={index}>Total: {totalTotalScore}</TableCell>
                );
              } else {
                return <TableCell key={index} />;
              }
            }
            return null;
          })}
        </TableRow>
      </TableFooter>
    );
  }, []);

  const options = {
    filterType: "checkbox",
    selectableRowsHeader: false,
    selectableRows: "none",
    customTableBodyFooterRender,
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MUIDataTable
      title={"Completed Surveys"}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export { CompletedSurveysSection };
