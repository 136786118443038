import styled from 'styled-components';
import { breakpoints } from '../../util/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 42px;
  margin: 24px auto;
  width: 325px;

  @media (min-width: ${breakpoints.sm}px) {
    width: 700px;
  }
`;

const Header = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin: 12px;
  text-align: center;
  text-transform: uppercase;
`;

const SuccessMessage = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 8px;
  text-align: center;
  text-transform: capitalize;
  color: green;
`;

const ItemRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: center;
`;

const FormElement = styled.div`
  display: flex;
  flex: 1;
  margin: 12px 0;

  @media (min-width: ${breakpoints.sm}px) {
    margin: 12px 12px;
  }
`;

export {
  Container,
  ButtonContainer,
  ItemRow,
  Header,
  FormElement,
  SuccessMessage
};
