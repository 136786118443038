import { useCallback, useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { formatDateFromTimestamp } from "../util/util";

const columns = [
  {
    name: "code",
    label: "Code",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "userId",
    label: "Claimed By",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "claimedAt",
    label: "Date Claimed",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "completedAt",
    label: "Date Completed",
    options: {
      filter: true,
      sort: true,
    },
  },
];

const options = {
  filterType: "checkbox",
  selectableRowsHeader: false,
  selectableRows: "none",
};

const useGetSurveyCodes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const firestore = useFirestore();

  const getSurveyCodes = useCallback(() => {
    setIsLoading(true);

    const surveyCodesRef = firestore.collection("surveyCodes");
    surveyCodesRef
      .get()
      .then((data) => {
        const list = data.docs.map((doc, index) => ({
          index: index + 1,
          code: doc.data().code,
          status: doc.data().status,
          claimedAt: doc.data().claimedAt
            ? formatDateFromTimestamp(doc.data().claimedAt)
            : "",
          completedAt: doc.data().completedAt
            ? formatDateFromTimestamp(doc.data().completedAt)
            : "",
          userId: doc.data().userId ? doc.data().userId : "",
        }));
        setData(list);
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [firestore]);

  useEffect(() => {
    getSurveyCodes();
  }, [getSurveyCodes]);

  return { error, isLoading, data, options, columns };
};

export { useGetSurveyCodes };
