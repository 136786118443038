import styled from 'styled-components';
import { breakpoints, colors } from '../../../util/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white.primary};
  margin: 24px 0;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.gray.background};
  border: 1px solid ${colors.gray.background};
  margin: 18px 0;

  @media (min-width: ${breakpoints.sm}px) {
    width: 500px;
    margin: 0px 18px;
  }
`;

const Text = styled.p`
  color: black;
  text-align: justify;
  font-size: 14px;
  line-height: 26px;
  padding: 0 24px; 
  margin: 8px 0;

  @media (min-width: ${breakpoints.sm}px) {
    font-size: 16px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #A30000;
  border-top: 8px solid #b35656;
  font-weight: bold;
  font-size: 26px;
  padding: 18px 0;
  text-transform: uppercase;
`;

export { Container, TextBox, Text, Title };
