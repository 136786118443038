import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "../Button";
import Loading from "../Loading";
import { SET_USER_SURVEY_CODE } from "../../../redux/actions/actions";
import {
  Wrapper,
  Header,
  SurveyRow,
  Paragraph,
  ValidationText,
} from "./surveyCode.styles";

const COMPLETED = "COMPLETED";
const CLAIMED = "CLAIMED";

export const SurveyCodeForm = ({ successCallback }) => {
  const [surveyCode, setSurveyCode] = useState("");
  const [validationText, setValidationText] = useState("");
  const [displayLoading, setDisplayLoading] = useState(false);

  const dispatch = useDispatch();
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebase.auth);

  const submitSurveyCode = async () => {
    setDisplayLoading(true);

    const surveyCodeRef = firestore.collection("surveyCodes");
    const query = await surveyCodeRef
      .where("code", "==", surveyCode.toLocaleUpperCase())
      .get();

    if (query.empty) {
      setValidationText("Invalid Code!"); // NOT FOUND
      setDisplayLoading(false);
      return;
    }

    const data = query.docs[0].data();
    const { code, status, userId } = data;

    if (status === COMPLETED) {
      setValidationText("Code has been used!"); // Code already completed
      setDisplayLoading(false);
      return;
    }

    if (status === CLAIMED && userId !== auth.uid) {
      setValidationText("Code already claimed!"); // Code in use
      setDisplayLoading(false);
      return;
    }

    // update code to CLAIMED with userId and date
    await surveyCodeRef.doc(code).update({
      status: CLAIMED,
      userId: auth.uid,
      claimedAt: firestore.FieldValue.serverTimestamp(),
    });

    dispatch({ type: SET_USER_SURVEY_CODE, payload: surveyCode });
    setValidationText("Valid Code!");
    setDisplayLoading(false);
    successCallback();
  };

  const disableSurveyCodeBtn = surveyCode.length < 6;

  return (
    <Wrapper>
      <Header>SMILE Assessment</Header>
      <SurveyRow>
        <Paragraph>
          Survey codes can be purchased through the products page. If you have
          already purchased, check your e-mail for a survey code to enter below.
          Enter your Survey Code below to begin the assessment.
        </Paragraph>
        <TextField
          type="text"
          label="Enter Your Survey Code"
          fullWidth
          variant="outlined"
          onChange={(e) => setSurveyCode(e.target.value)}
          value={surveyCode}
        />
      </SurveyRow>
      <SurveyRow>
        <ValidationText isValid>{validationText}</ValidationText>
        {displayLoading ? (
          <Loading />
        ) : (
          <Button
            disabled={disableSurveyCodeBtn}
            label="Submit"
            action={submitSurveyCode}
          />
        )}
      </SurveyRow>
    </Wrapper>
  );
};

SurveyCodeForm.propTypes = {
  successCallback: PropTypes.func.isRequired,
};
export default SurveyCodeForm;
