import React from "react";
import styled from "styled-components";
import { useFirebaseUser } from "../../hooks/useFirebaseUser";
import { ROUTES } from "../../util/routes";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #a30000;
  align-items: center;
  padding: 12px;
  margin-bottom: 8px;
  box-shadow: 0 0 10px #333;
`;
const MenuItem = styled.div`
  text-transform: capitalize;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  margin-right: 16px;

  :hover {
    color: #eb9f23;
    text-decoration: underline;
  }
`;

const MenuBar = () => {
  const { isLoggedIn } = useFirebaseUser();

  const accountAction = () => {
    if (isLoggedIn) {
      window.location = ROUTES.profile;
    } else {
      window.location = ROUTES.login;
    }
  };

  return (
    <Container>
      <MenuItem onClick={accountAction}>
        {isLoggedIn ? "My Account" : "Login / Register"}
      </MenuItem>
      <MenuItem onClick={() => (window.location = ROUTES.contact)}>
        Contact Us
      </MenuItem>
      <MenuItem onClick={() => (window.location = ROUTES.products)}>
        Products
      </MenuItem>
    </Container>
  );
};

export default MenuBar;
