import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`;

const Footer = styled.div`
  background-color: #2296f3;
  align-self: flex-end;
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding: 12px 0;
  text-align: center;
  width: 100%;
`;

export { Container, Content, Footer };
