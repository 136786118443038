import React from 'react';
import PropTypes from 'prop-types';
import AccountBalance from '@material-ui/icons/AccountBalance';
import styled from 'styled-components';
import Button from '../../Common/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const Title = styled.h2`
  font-weight: bold;
  text-transform: uppercase;
  color: #f0a91d;
`;
const SubText = styled.div`
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  color: #000;
  margin-bottom: 24px;
  width: 70%;
`;

const SolutionsBox = ({ title, subtext, buttonText, buttonAction }) => (
  <Container>
    <AccountBalance style={{ color: '#f0a91d', width: '42px', height: '42px' }} />
    <Title>{title}</Title>
    <SubText>{subtext}</SubText>
    <Button
      label={buttonText}
      action={buttonAction}
      alt="true"
    />
  </Container>
  );

  SolutionsBox.defaultProps = {
  buttonText: '',
  buttonAction: () => {}
};

SolutionsBox.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
}

export default SolutionsBox;
