import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from '@reach/router';
import { ROUTES } from '../../../util/routes';
import Button from '../../Common/Button';
import {
  Container,
  Paragraph,
  SubText,
} from './notAuthorized.styles';

const Completed = ({ scores }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const navigate = useNavigate();

  const finishAction = () => {
    navigate(ROUTES.root);
  };

  return (
    <Container>
      <Paragraph>You are not authorized to view this page!</Paragraph>
      <SubText>To take the assessment, you must be logged in and enter a valid survey code at your profile screen.</SubText>
      <Button action={finishAction} label="Take Me Back" />
    </Container>
  )
}
Completed.propTypes = {
  scores: PropTypes.array.isRequired
};

Completed.defaultProps = {
  scores: []
};

export default Completed;