import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "@reach/router";
import {
  getScore,
  getOverallScore,
  getCategoryScores,
  getUnderstandingText,
} from "../../../../util/scoreUtil";
import ReflectiveThoughtsForm from "../../../ReflectiveThoughtsForm";
import { REMOVE_USER_SURVEY_CODE } from "../../../../redux/actions/actions";
import { ROUTES } from "../../../../util/routes";
import {
  IndividualScoresTable,
  TotalScoresTable,
} from "../../../Common/ScoresTable";

import {
  Container,
  Paragraph,
  Results,
  ResultsText,
  ResultsWrapper,
  SubRow,
  SubItemText,
  SubText,
  HorizontalRule,
  UnderstandingText,
  Link,
} from "./completed.styles";

const Completed = ({ scores }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gotoProfile = () => {
    dispatch({ type: REMOVE_USER_SURVEY_CODE });
    navigate(ROUTES.profile);
  };

  const gotoRecommendations = () => {
    dispatch({ type: REMOVE_USER_SURVEY_CODE });
    navigate(ROUTES.recommendation);
  };

  const totalScore = getScore(scores);
  return (
    <Container>
      <Paragraph>Thank you for completing the S.M.I.L.E survey!</Paragraph>
      <SubText>
        Below you will find your results, individual and total scores, and a
        reflective thoughts form to complete.
      </SubText>
      <SubText>
        You can always find your scores and results in your{" "}
        <Link onClick={gotoProfile}>profile</Link> at a later time.
      </SubText>
      <SubText>
        For more information on your results, visit the{" "}
        <Link onClick={gotoRecommendations}>Sustainability Resources</Link>.
      </SubText>
      <ResultsWrapper>
        <Results>Total Score: {totalScore}</Results>
        {Object.entries(getCategoryScores(scores)).map(([key, value]) => (
          <SubRow key={key}>
            <SubItemText>{key}</SubItemText>
            <SubItemText>
              Score: <strong>{value}</strong>
            </SubItemText>
          </SubRow>
        ))}
        <ResultsText>
          Your score suggests {getOverallScore(totalScore)}
        </ResultsText>
        <UnderstandingText>
          {getUnderstandingText(totalScore)}
        </UnderstandingText>
      </ResultsWrapper>

      <IndividualScoresTable />
      <TotalScoresTable />

      <HorizontalRule />

      <ReflectiveThoughtsForm />
    </Container>
  );
};
Completed.propTypes = {
  scores: PropTypes.array.isRequired,
};

Completed.defaultProps = {
  scores: [],
};

export default Completed;
