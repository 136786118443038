import React, { useEffect, useState } from "react";
import { useNavigate } from "@reach/router";
import { isLoaded, isEmpty } from "react-redux-firebase";
import Profile from "./Profile";
import SurveyCode from "../Common/SurveyCode";
import SurveyHistory from "../Common/SurveyHistory";
import { ROUTES } from "../../util/routes";
import {
  Wrapper,
  MenuWrapper,
  MenuHeader,
  MenuItem,
  ContentWrapper,
} from "./container.styles";
import { useFirebaseUser } from "../../hooks/useFirebaseUser";
import { Administration } from "../Common/Administration";
import { PurchasedSurveyCodes } from "../Common/PurchasedSurveyCodes";

const ACCOUNT_STATE = {
  profile: "PROFILE",
  purchases: "PURCHASES",
  history: "HISTORY",
  surveyCode: "SURVEY_CODE",
  purchasedCodes: "PURCHASED_CODES",
  admin: "ADMIN",
};
export const AccountPage = () => {
  const { isAdmin, auth } = useFirebaseUser();

  const [accountState, setAccountState] = useState(ACCOUNT_STATE.profile);
  const navigate = useNavigate();

  const notAuthorized = isLoaded(auth) && isEmpty(auth);

  useEffect(() => {
    if (notAuthorized) {
      navigate(ROUTES.root); // user not authenticated; peace out...
    }
  }, [auth, navigate, notAuthorized]);

  const navigateToSurvey = () => {
    navigate(ROUTES.assessment);
  };

  const getContent = () => {
    switch (accountState) {
      case ACCOUNT_STATE.admin:
        return <Administration />;
      case ACCOUNT_STATE.history:
        return <SurveyHistory />;
      case ACCOUNT_STATE.surveyCode:
        return <SurveyCode successCallback={navigateToSurvey} />;
      case ACCOUNT_STATE.purchasedCodes:
        return <PurchasedSurveyCodes />;
      default:
        return <Profile />;
    }
  };

  if (isEmpty(auth)) return null;

  return (
    <Wrapper>
      <MenuWrapper>
        <MenuHeader>Menu</MenuHeader>
        <MenuItem
          onClick={() => setAccountState(ACCOUNT_STATE.profile)}
          selected={accountState === ACCOUNT_STATE.profile}
        >
          My Profile
        </MenuItem>
        <MenuItem
          onClick={() => setAccountState(ACCOUNT_STATE.surveyCode)}
          selected={accountState === ACCOUNT_STATE.surveyCode}
        >
          Enter Survey Code
        </MenuItem>
        <MenuItem
          onClick={() => setAccountState(ACCOUNT_STATE.history)}
          selected={accountState === ACCOUNT_STATE.history}
        >
          Assessment History
        </MenuItem>
        <MenuItem
          onClick={() => setAccountState(ACCOUNT_STATE.purchasedCodes)}
          selected={accountState === ACCOUNT_STATE.purchasedCodes}
        >
          Purchased Survey Codes
        </MenuItem>
        {isAdmin ? (
          <MenuItem
            onClick={() => setAccountState(ACCOUNT_STATE.admin)}
            selected={accountState === ACCOUNT_STATE.admin}
          >
            Administration
          </MenuItem>
        ) : null}
      </MenuWrapper>
      <ContentWrapper>{getContent()}</ContentWrapper>
    </Wrapper>
  );
};

export default AccountPage;
