export const calculatePercentage = (progress) => `${progress * 100}%`;

// export const calculateProgress = (currentIndex, totalIndexes) => {
//   if (currentIndex >= totalIndexes) return "100%";
//   return calculatePercentage(currentIndex / totalIndexes);
// };

export const calculateProgress = (currentIndex) => {
  switch (currentIndex) {
    case 1:
      return "0%";
    case 2:
      return "25%";
    case 3:
      return "50%";
    case 4:
      return "75%";
    default:
      return "100%";
  }
};
