import React from 'react';
import PropTypes from 'prop-types';
import { 
  ProgressBarWrapper,
  ProgressIndicator,
  ProgressText
 } from './ProgressBar.styles';

const ProgressBar = ({ currentProgress }) => (
  <ProgressBarWrapper>
    <ProgressIndicator currentProgress={currentProgress} />
    <ProgressText>Current Progress {currentProgress}</ProgressText>
  </ProgressBarWrapper>
);

ProgressBar.propTypes = {
  currentProgress: PropTypes.string.isRequired,
};

export default ProgressBar;
