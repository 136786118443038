import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import Message from '../Common/Message';
import Loading from '../Common/Loading';
import Button from '../Common/Button';
import { Container, ItemRow, Header, ButtonContainer, FormElement, SuccessMessage } from './ContactForm.styles';

export const ContactForm = ({ auth, profile }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [displayLoading, setDisplayLoading] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const firestore = useFirestore();
  const firebase = useFirebase();

  const clearForm = () => {
    setEmail('');
    setPhone('');
    setMessage('');
    setFirstName('');
    setLastName('');
  };

  const persistFeedback = async (request) => {
    const feedbackRef = firestore.collection('feedback');
    const snapshot = await feedbackRef.get();
    if (!snapshot.exists) {
      const payload = { 
        ...request,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      await feedbackRef.add(payload);
      setDisplayLoading(false);
      setShowSuccessMsg(true);
      clearForm();
    }
  };

  const handleSubmit = () => {
    setShowSuccessMsg(false);
    setErrorMessage('');
    setDisplayLoading(true);

    const requestParams = {
      firstName,
      lastName,
      email,
      phone,
      message,
    };
    persistFeedback(requestParams);
  };

  const disableButton = !firstName || !lastName || !email || !message;

  return ( 
    <Container>
      {showSuccessMsg && <SuccessMessage>Thank you for feedback!</SuccessMessage>}
      <Header>Contact Us</Header>
      {errorMessage &&
        <Message text={errorMessage} handleClose={() => setErrorMessage('') } /> 
      }
      <ItemRow>
        <FormElement>
          <TextField
            type="text"
            label="First Name *"
            fullWidth
            variant="outlined"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormElement>
        <FormElement>
          <TextField
            type="text"
            label="Last Name *"
            fullWidth
            variant="outlined"
            onChange={(e) => setLastName(e.target.value)}
          />
        </FormElement>
      </ItemRow>
      <ItemRow>
        <FormElement>
          <TextField
            label="Email Address *"
            fullWidth
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormElement>
        <FormElement>
          <TextField
            type="number"
            label="Phone Number"
            fullWidth
            variant="outlined"
            onChange={(e) => setPhone(e.target.value)}
          />
        </FormElement>
      </ItemRow>
      <ItemRow>
        <FormElement>
          <TextField
            label="Message *"
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            onChange={(e) => setMessage(e.target.value)}
          />
        </FormElement>
      </ItemRow>
      <ButtonContainer>
        <Button
          disabled={disableButton} 
          action={handleSubmit}
          label="Submit" 
        />
      </ButtonContainer>
        {displayLoading && <Loading />}
    </Container>
  );
};

export default ContactForm;
