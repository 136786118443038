import { useFirestore } from "react-redux-firebase";
import { useCallback, useEffect, useState } from "react";

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "displayName",
    label: "Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "race",
    label: "Race",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "organization",
    label: "Organization",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "age",
    label: "Age",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "gender",
    label: "Gender",
    options: {
      filter: true,
      sort: true,
    },
  },
];

const options = {
  filterType: "checkbox",
  selectableRowsHeader: false,
  selectableRows: "none",
};

const useGetUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const firestore = useFirestore();

  const getUsersList = useCallback(() => {
    setIsLoading(true);

    const usersRef = firestore.collection("users");
    usersRef
      .get()
      .then((data) => {
        const list = data.docs.map((doc, index) => ({
          index: index + 1,
          id: doc.id,
          displayName: doc.data().displayName,
          organization: doc.data().organization ? doc.data().organization : "",
          email: doc.data().email,
          race: doc.data().race,
          age: doc.data().age,
          gender: doc.data().gender,
        }));
        setData(list);
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [firestore]);

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  return { isLoading, error, data, columns, options };
};

export { useGetUsers };
