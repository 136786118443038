import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import { Container, Icon, TextStyle, CloseIconWrapper } from './Message.styles';

export const Message = ({ handleClose, text }) => {
  return (
    <Container>
      <Icon> <ErrorIcon /></Icon>
      <TextStyle>{text}</TextStyle>
      <CloseIconWrapper onClick={handleClose}> <CloseIcon /></CloseIconWrapper>
    </Container>
  )
}

Message.propTypes = {
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Message;
