import React from "react";
import MUIDataTable from "mui-datatables";
import { useGetUsers } from "../../../hooks/useGetUsers";
import Loading from "../Loading";

const UsersSection = () => {
  const { data, isLoading, columns, options } = useGetUsers();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MUIDataTable
      title={"Users List"}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export { UsersSection };
