import { useFirestore } from "react-redux-firebase";
import { useCallback, useEffect, useState } from "react";

const columns = [
  {
    name: "userId",
    label: "User ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "userEmail",
    label: "User Email",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "firstName",
    label: "First Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "lastName",
    label: "Last Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "orderId",
    label: "Order ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "payerId",
    label: "Payer ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "productType",
    label: "Product",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "paymentAmount",
    label: "Payment",
    options: {
      filter: true,
      sort: true,
    },
  },
];

const options = {
  filterType: "checkbox",
  selectableRowsHeader: false,
  selectableRows: "none",
};

const useGetPaymentTransactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const firestore = useFirestore();

  const getPaymentTransactions = useCallback(() => {
    setIsLoading(true);

    const transactionsRef = firestore.collection("paymentTransactions");
    transactionsRef
      .get()
      .then((data) => {
        const list = data.docs.map((doc, index) => ({
          index: index + 1,
          userId: doc.data().userId,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          userEmail: doc.data().userEmail,
          productType: doc.data().productType,
          orderId: doc.data().orderId,
          payerId: doc.data().payerId,
          paymentAmount: doc.data().paymentAmount,
        }));
        setData(list);
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [firestore]);

  useEffect(() => {
    getPaymentTransactions();
  }, [getPaymentTransactions]);

  return { isLoading, error, data, columns, options };
};

export { useGetPaymentTransactions };
