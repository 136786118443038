import styled from "styled-components";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import Bookmark from "@material-ui/icons/Bookmark";
import { breakpoints } from "../../util/theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  padding: 62px 0;
  margin: 24px 0;
`;

const ListItemIcon = styled(LocalLibrary)`
  margin: 0 8px;
`;

const SubListItemIcon = styled(Bookmark)`
  margin-left: 24px;
  margin-right: 8px;
`;

const ItemRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 18px;
  text-align: center;
`;

const Description = styled.div`
  display: flex;
  font-size: 14px;
  margin: 8px 0;
  align-items: center;
`;

const Price = styled.p`
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  padding: 12px;
  margin: 24px 0;
  align-self: center;
  width: 200px;
  color: white;
  background-color: #a30000;
  box-shadow: 0 0 6px #333;
`;

const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  margin: 12px 24px;
  padding: 12px;
  border: 1px solid gray;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
`;

export {
  Container,
  ItemRow,
  ProductItem,
  Title,
  Description,
  Price,
  ListItemIcon,
  SubListItemIcon,
  ButtonContainer,
};
