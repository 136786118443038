import React, { useState } from 'react';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from '@reach/router';
import TextField from '@material-ui/core/TextField';
import Message from '../Common/Message';
import Loading from '../Common/Loading';
import Button from '../Common/Button';
import { ROUTES } from '../../util/routes';
import { REMOVE_USER_SURVEY_CODE } from '../../redux/actions/actions';
import { Container, ItemRow, Header, ButtonContainer, SuccessMessage } from './ReflectiveThoughts.styles';

export const ReflectiveThoughtsForm = () => {
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [answer4, setAnswer4] = useState('');
  const [answer5, setAnswer5] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [displayLoading, setDisplayLoading] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const firestore = useFirestore();
  const firebase = useFirebase();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(state => state.firebase.profile);
  const auth = useSelector(state => state.firebase.auth);

  const persistFeedback = async (request) => {
    const feedbackRef = firestore.collection('reflectiveThoughtsFeedback');
    const snapshot = await feedbackRef.get();
    if (!snapshot.exists) {
      const payload = { 
        ...request,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      await feedbackRef.add(payload);
      setDisplayLoading(false);
      setShowSuccessMsg(true);
    }
  };

  const handleSubmit = () => {
    setErrorMessage('');
    setDisplayLoading(true);

    const { email, displayName } = user;
    const { uid } = auth;
  
    const requestParams = {
      userId: uid,
      displayName,
      email,
      answer1,
      answer2,
      answer3,
      answer4,
      answer5,
    };

    persistFeedback(requestParams);
    dispatch({ type: REMOVE_USER_SURVEY_CODE });
    navigate(ROUTES.profile);
  };

  const disableButton = !answer1 || !answer2 || !answer3 || !answer4 || !answer5 || showSuccessMsg;

  return ( 
    <Container>
      <Header>Reflective Thoughts</Header>
      {errorMessage &&
        <Message text={errorMessage} handleClose={() => setErrorMessage('') } /> 
      }
      <ItemRow>
        <TextField
          label="How do the assessment scores reflect what you believe about yourself?"
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          onChange={(e) => setAnswer1(e.target.value)}
        />
      </ItemRow>
      <ItemRow>
        <TextField
          label="What did you find most affirming?"
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          onChange={(e) => setAnswer2(e.target.value)}
        />
      </ItemRow>
      <ItemRow>
        <TextField
          label="What did you find concerning or surprising?"
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          onChange={(e) => setAnswer3(e.target.value)}
        />
      </ItemRow>
      <ItemRow>
        <TextField
          label="What did you learn about yourself?"
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          onChange={(e) => setAnswer4(e.target.value)}
        />
      </ItemRow>
      <ItemRow>
        <TextField
          label="What area(s) do you need to work on?"
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          onChange={(e) => setAnswer5(e.target.value)}
        />
      </ItemRow>
      {showSuccessMsg && <SuccessMessage>Thank you for feedback!</SuccessMessage>}
      <ButtonContainer>
        <Button
          disabled={disableButton} 
          action={handleSubmit}
          label="Submit" 
        />
      </ButtonContainer>
      {displayLoading && <Loading />}
    </Container>
  );
};

export default ReflectiveThoughtsForm;
