import MUIDataTable from "mui-datatables";
import React from "react";
import { useGetSurveyCodes } from "../../../hooks/useGetSurveyCodes";
import Loading from "../Loading";

const SurveyCodesSection = () => {
  const { isLoading, data, columns, options } = useGetSurveyCodes();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MUIDataTable
      title={"Survey Codes"}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export { SurveyCodesSection };
