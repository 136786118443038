export const USER_STATUS = ["ACTIVE", "PENDING"];
export const SURVEY_CODE_STATUS = ["INACTIVE", "COMPLETED", "ISSUED"];
export const ROLE_ADMIN = "ROLE_ADMIN";

export const RACES = [
  "White",
  "Black or African American",
  "Asian or Pacific Islander",
  "Native American or American Indian",
  "Hispanic or Latino",
  "Other",
  "Prefer not to answer",
];
export const GENDERS = [
  "Female",
  "Male",
  "Transgender female",
  "Transgender male",
  "Gender variant/non-conforming",
  "Not Listed",
  "Prefer not to answer",
];

export const AGE_RANGES = [
  "Under 18",
  "18-24",
  "25-34",
  "35-44",
  "45-54",
  "55-64",
  "65+",
];

export const STATES = [
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];
