import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 450px;
`;

const Header = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: black;
  margin-bottom: 4px;
  text-align: center;
`;

const SurveyRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 30px;
  color: black;
  margin-bottom: 28px;
`;

const ValidationText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: green;
  text-align: left;
  margin: 12px 0;
  text-transform: uppercase;
`;

export {
  Wrapper,
  Header,
  SurveyRow,
  Paragraph,
  ValidationText
};
