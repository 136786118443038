import styled from 'styled-components';
import { breakpoints } from '../../../util/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top: 24px solid black;
  border-bottom: 24px solid black;
  width: 100%;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }
`;

const ImageRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background-image: url('bgs/home_bg_1.jpg');
  /* background-position: center;  */
  background-size: cover;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f8fa;
  padding: 0px;
  width: 100%;

  @media (min-width: ${breakpoints.sm}px) {
    width: 500px;
    padding: 12px;
  }
`;

const InfoItem = styled.div`
  margin: 12px 0;
`;

const InfoTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin: 12px 0;
  text-align: center;
  color: #A30000;
`;

const InfoText = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin-left: 12px;
  text-align: center;
`;

const Header = styled.h3`
  padding: 0;
  margin: 8px 0;
  text-align: center;
  font-size: 28px;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;


export {
  Container,
  Header,
  InfoColumn,
  InfoTitle,
  InfoText,
  InfoItem,
  ButtonWrapper,
  ImageRow 
};
