import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { Form } from './dropdown.styles';

export const DropDownMenu = ({ data, value, label, onChange }) => {
  return (
    <Form>
      <InputLabel htmlFor={label}>{label}</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          inputProps={{ name: `name_${label}`, id: `id_${label}`}}
        >
        {data.map(item => {
          const id = item.id || item;
          const name = item.name || item;
          return ( <MenuItem key={`${id}_${name}`} value={id}>{name}</MenuItem> )
        })}
        </Select>
    </Form>
  )
}

DropDownMenu.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default DropDownMenu;

