import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

const useFirebaseUser = () => {
  const auth = useSelector((state) => state.firebase.auth);
  const user = useSelector((state) => state.firebase.profile);
  const isLoggedIn = isLoaded(auth) && !isEmpty(auth) && !!user.displayName;
  const isAdmin = isLoggedIn && !!user.isAdmin;

  return { auth, user, isLoggedIn, isAdmin };
};

export { useFirebaseUser };
