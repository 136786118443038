import React, { useState } from "react";
import { useGenerateAndInsertSurveyCodes } from "../../../hooks/useGenerateAndInsertSurveyCodes";
import Button from "../Button";
import DropDownMenu from "../DropDown";
import Loading from "../Loading";
import {
  AdminTable,
  AdminTableHeaderCell,
  AdminTableHeaderRow,
  AdminTableBody,
  AdminTableRow,
  AdminTableBodyCell,
  ButtonRow,
  Count,
  DropdownWrapper,
} from "./adminTable.styles";

const DROP_DOWN_VALUES = [0, 1, 10, 25, 100];

const GenerateCodesSection = () => {
  const [numberOfCodes, setNumberOfCodes] = useState(DROP_DOWN_VALUES[0]);
  const { isLoading, codesInserted, insertCodes } =
    useGenerateAndInsertSurveyCodes();

  const disableButton =
    isLoading || numberOfCodes === 0 || !!codesInserted.length;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ButtonRow>
        <DropdownWrapper>
          <DropDownMenu
            label="Number of Codes to Generate"
            value={numberOfCodes}
            onChange={(e) => setNumberOfCodes(e.target.value)}
            data={DROP_DOWN_VALUES}
          />
        </DropdownWrapper>

        <Button
          label="Generate Codes"
          disabled={disableButton}
          action={() => insertCodes(numberOfCodes)}
        />

        {codesInserted.length ? (
          <Count> {`${codesInserted.length} Code(s) Generated`}</Count>
        ) : null}
      </ButtonRow>

      {codesInserted.length > 0 ? (
        <AdminTable>
          <AdminTableHeaderRow>
            <AdminTableHeaderCell>Code</AdminTableHeaderCell>
          </AdminTableHeaderRow>
          <AdminTableBody>
            {codesInserted.map((code) => (
              <AdminTableRow key={code}>
                <AdminTableBodyCell>{code}</AdminTableBodyCell>
              </AdminTableRow>
            ))}
          </AdminTableBody>
        </AdminTable>
      ) : null}
    </>
  );
};

export { GenerateCodesSection };
