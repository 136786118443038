import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "@reach/router";
import { isLoaded, isEmpty } from "react-redux-firebase";
import SolutionsBox from "../../Common/SolutionsBox";
import { ROUTES } from "../../../util/routes";
import { Container, Row } from "./ProductsRow.styles";

const ProductsRow = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.firebase.auth);
  const isLoggedIn = isLoaded(auth) && !isEmpty(auth);

  const goToLoginOrProfile = () => {
    if (isLoggedIn) {
      navigate(ROUTES.profile);
    } else {
      navigate(ROUTES.login);
    }
  };

  return (
    <Container>
      <Row>
        <SolutionsBox
          title="Organizations"
          subtext="Improve the effectiveness of your company, team, or classroom"
          buttonText="Prepare Your Staff"
          buttonAction={goToLoginOrProfile}
        />
        <SolutionsBox
          title="Individuals"
          subtext="View our assessment of your cultural behavior upon completion of the survey"
          buttonText="Begin Your Assessment"
          buttonAction={goToLoginOrProfile}
        />
        <SolutionsBox
          title="Products"
          subtext="View a detailed list of all our products and pricing we have to offer"
          buttonText="Browse All"
          buttonAction={() => navigate(ROUTES.products)}
        />
      </Row>
    </Container>
  );
};

export default ProductsRow;
