import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StyledTableCell, useStyles } from "./sharedStyles";

const IndividualScoresTable = () => {
  const classes = useStyles();

  const totalScoresData = [
    {
      id: 0,
      score: "60 - 120",
      definition:
        "Suggests poor ability to effectively engage with diverse groups",
    },
    {
      id: 1,
      score: "121 - 180",
      definition:
        "Suggests minimal ability to effectively engage with diverse groups",
    },
    {
      id: 2,
      score: "181 - 240",
      definition:
        "Suggests satisfactory ability to effectively engage with diverse groups",
    },
    {
      id: 3,
      score: "241 - 300",
      definition:
        "Suggests strong ability to effectively engage with diverse groups",
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Score Range</StyledTableCell>
            <StyledTableCell align="right">
              Total Component Definition
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {totalScoresData.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.score}
              </TableCell>
              <TableCell align="right">{row.definition}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IndividualScoresTable;
