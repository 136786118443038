export const FETCH_ASSESMENT = 'FETCH_ASSESMENT';
export const SET_SCORING_VALUES = 'SET_SCORING_VALUES';

export const SET_USER_SCORES = 'SET_USER_SCORES';
export const CLEAR_USER_SCORES = 'CLEAR_USER_SCORES';

export const SET_USER_SURVEY_CODE = 'SET_USER_SURVEY_CODE';
export const REMOVE_USER_SURVEY_CODE = 'REMOVE_USER_SURVEY_CODE';

export const SIGN_OUT = 'SIGN_OUT';
