import { 
  FETCH_ASSESMENT,
  SET_SCORING_VALUES,
  SET_USER_SURVEY_CODE,
  REMOVE_USER_SURVEY_CODE, 
  SIGN_OUT,
  SET_USER_SCORES,
  CLEAR_USER_SCORES
} from '../actions/actions';

const DEFAULT_ASSESSMENT_DATA = {
  categories: [],
  questions: [],
  scores: [],
};

const USER_DEFAULT_STATE = {
  surveyCode: '',
  userScores: [],
};

export const assessmentData = (state = DEFAULT_ASSESSMENT_DATA, action) => {
  switch (action.type) {
    case FETCH_ASSESMENT: {
      const categories = action.payload;
      return { ...state, categories };
    }
    case SET_SCORING_VALUES: {
      const scores = action.payload;
      return { ...state, scores };
    }
    case SIGN_OUT: {
      return { ...DEFAULT_ASSESSMENT_DATA };
    }
    default: {
      return state;
    }
  }
}

export const userData = (state = USER_DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_USER_SCORES: {
      const data = action.payload;
      const { scores, surveyCode } = data; // [ { questionId: 100, score: 1 }, { questionId: 101, score: 2 } ] = scores
      const userScores = { [surveyCode]: { ...scores, ...state.userScores[surveyCode] } };
      return { ...state, userScores };
    }
    case CLEAR_USER_SCORES: {
      return { ...state, userScores: {} };
    }
    case SET_USER_SURVEY_CODE: {
      const surveyCode = action.payload;
      return { ...state, surveyCode };
    }
    case REMOVE_USER_SURVEY_CODE: {
      return { ...state, surveyCode: '' };
    }
    default: {
      return state;
    }
  }
}