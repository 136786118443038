import styled from 'styled-components';
import { breakpoints, colors } from '../../../util/theme';
import LocalLibrary from '@material-ui/icons/LocalLibrary';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  margin: 24px 0;
  background-color: ${colors.gray.background};

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    height: 500px;
  }
`;

const LeftColumn = styled.div`
  display: none;
  background-image: url('bgs/education_bg.jpg');
  background-position: center; 
  background-size: cover;
  width: 40%;
  border: 6px solid #A30000;

  @media (min-width: ${breakpoints.md}px) {
  display: flex;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  background-color: #f4f8fa;

  @media (min-width: ${breakpoints.md}px) {
    padding: 0 12px;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin: 8px;
  padding: 4px;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
`;

const ListItemIcon = styled(LocalLibrary)`
  margin: 0 8px;
`;

const Title = styled.h2`
  align-self: center;
  font-weight: bold;
  color: #000;
  font-size: 28px;
  text-transform: uppercase;
`;

export { Container, LeftColumn, RightColumn, Title, ListItem, ListItemIcon };
