import React from 'react';
import { useNavigate } from '@reach/router';
import Button from '../../Common/Button';
import { 
  Container,
  InfoColumn,
  ImageRow,
  InfoTitle,
  InfoText,
  InfoItem,
  ButtonWrapper
} from './MissionRow.styles';
import { ROUTES } from '../../../util/routes';

const MissionRow = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <InfoColumn>
        <InfoItem>
          <InfoTitle>S.M.I.L.E.</InfoTitle>
          <InfoText>The Survey of Multicultural Interactions and Linguistic Engagement</InfoText>
        </InfoItem>
        <InfoItem>
          <InfoTitle>Self Rating Tool</InfoTitle>
          <InfoText>For assessing cultural competence and uncovering and addressing implicit bias</InfoText>
        </InfoItem>
        <InfoItem>
          <InfoTitle>Areas of Focus</InfoTitle>
          <InfoText>Belief and attitude, knowledge, skill and experience</InfoText>
        </InfoItem>
        <InfoItem>
          <InfoTitle>Purpose</InfoTitle>
          <InfoText>Reflection, progress monitoring and a springboard for increasing knowledge and understanding of diverse groups</InfoText>
        </InfoItem>
        <InfoItem>
          <InfoTitle>Benefit</InfoTitle>
          <InfoText>Develop more effective communication, collaboration, cultural competence and team building skills</InfoText>
        </InfoItem> 
        <ButtonWrapper>
          <Button alt="true" label="Register Now" action={() => navigate(ROUTES.login)} /> 
        </ButtonWrapper>      
      </InfoColumn>
      
      <ImageRow />
    </Container>
  )
};

export default MissionRow;