import styled from 'styled-components';

const Container = styled.div`
  background-color: #cc0202;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

const Icon = styled.div`
  justify-self: flex-start;
  margin-left: 8px;
`;

const TextStyle = styled.p`
  flex-grow: 1;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0;
  margin: 0 8px;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
  justify-self: flex-end;
  margin-right: 8px;
`;

export { Container, Icon, TextStyle, CloseIconWrapper };
