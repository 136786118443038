import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StyledTableCell, useStyles } from "./sharedStyles";

const IndividualScoresTable = () => {
  const classes = useStyles();

  const individualScoringData = [
    {
      id: 0,
      score: "15 - 30",
      definition: "Suggests a poor level of cultural competence in that area",
    },
    {
      id: 1,
      score: "31 - 45",
      definition:
        "Suggests a minimal level of cultural competence in that area",
    },
    {
      id: 2,
      score: "46 - 60",
      definition:
        "Suggests a satisfactory level of cultural competence in that area",
    },
    {
      id: 3,
      score: "61 - 75",
      definition: "Suggests a strong level of cultural competence in that area",
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Score Range</StyledTableCell>
            <StyledTableCell align="right">
              Individual Component Definition
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {individualScoringData.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.score}
              </TableCell>
              <TableCell align="right">{row.definition}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IndividualScoresTable;
