import styled from 'styled-components';

const StyledButton = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 50px;
  margin: 12px 0;
  color: #fff;
  border: #f0a91d;
  border-radius: 4px;
  font-size: ${props => (props.alt === 'true' ? '14px': '16px')};
  font-weight: 600;
  background-color: ${props => {
    if (props.disabled) {
      return '#ccc';
    } else if(props.alt === 'true') {
      return '#A30000'
    } else {
      return '#f0a91d';
    }
  }};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  text-transform: ${props => (props.alt === 'true' ? 'capitalize': 'uppercase')};;
  text-decoration: none;

  :hover {
    border: 1px solid  ${props => (props.disabled ? '#ccc' : 'red')};;
    background-color: ${props => (props.disabled ? '#ccc' : 'red')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
`;

export { StyledButton };
