import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  margin: 24px 0;
`;

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  border-bottom: 1px dotted gray;
`;

const ModuleTitle = styled.h3`
  font-size: 26px;
  background-color: black;
  color: white;
  text-align: left;
  padding: 8px 18px;
  border-radius: 10px;
  margin: 12px 0;
  width: 95%;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: 42px;
`;

export { ButtonContainer, Container, ModuleContainer, ModuleTitle };
