const categories = [
  {
    id: 1,
    name: "Belief/Attitude",
    description: "Individual, Professional & Societal Level",
    sortOrder: 1,
    createdAt: "2020-08-26 12:00:01",
  },
  {
    id: 2,
    name: "Knowledge",
    description: "Individual, Professional & Societal Level",
    sortOrder: 2,
    createdAt: "2020-08-26 12:00:01",
  },
  {
    id: 3,
    name: "Skill",
    description: "Individual, Professional & Societal Level",
    sortOrder: 3,
    createdAt: "2020-08-26 12:00:01",
  },
  {
    id: 4,
    name: "Experience",
    description: "Individual, Professional  & Societal Level",
    sortOrder: 4,
    createdAt: "2020-08-26 12:00:01",
  },
];

export { categories };
