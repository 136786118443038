import { useFirestore } from "react-redux-firebase";
import { useCallback, useEffect, useState } from "react";
import { formatReadableDateFromTimestamp } from "../util/util";
import { getCategoryScores } from "../util/scoreUtil";

const columns = [
  {
    name: "email",
    label: "Email",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "displayName",
    label: "Name",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "surveyCode",
    label: "Survey Code",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "organization",
    label: "Organization",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "belief",
    label: "Belief Score",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "knowledge",
    label: "Knowledge Score",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "skill",
    label: "Skill Score",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "experience",
    label: "Experience Score",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "totalScore",
    label: "Total Score",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "formattedDate",
    label: "Date Completed",
    options: {
      filter: false,
      sort: true,
      sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = obj1.rowData[10].seconds;
          let val2 = obj2.rowData[10].seconds;
          return (val1 - val2) * (order === "asc" ? 1 : -1);
        };
      },
    },
  },
  {
    name: "createdAt",
    label: "Created At",
    options: {
      filter: false,
      display: "excluded",
    },
  },
];

const useGetCompletedSurveys = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);

  const firestore = useFirestore();

  const getUsers = useCallback(() => {
    const usersCollectionRef = firestore.collection("users");
    usersCollectionRef.get().then((usersData) => {
      const usersList = usersData.docs.map((doc) => {
        return {
          id: doc.id,
          organization: doc.data().organization ?? "N/A",
        };
      });
      setUsers(usersList);
    });
  }, [firestore]);

  const getCompletedSurveysData = useCallback(() => {
    setIsLoading(true);
    const userScoresCollectionRef = firestore.collection("userScores");

    userScoresCollectionRef
      .get()
      .then((data) => {
        const list = data.docs.map((doc) => {
          const scoresObject = getCategoryScores(doc.data().scores);

          // find the user in the usersArray and attach the organization to the final object
          const foundUser = users?.find(
            (user) => user.id === doc.data().userId
          );
          const organization = foundUser ? foundUser.organization : "N/A";

          return {
            displayName: doc.data().displayName,
            email: doc.data().email,
            surveyCode: doc.data().surveyCode,
            organization,
            totalScore: doc.data().totalScore,
            formattedDate: doc.data().createdAt
              ? formatReadableDateFromTimestamp(doc.data().createdAt)
              : "",
            createdAt: doc.data().createdAt,
            belief: scoresObject["Belief/Attitude"],
            knowledge: scoresObject["Knowledge"],
            skill: scoresObject["Skill"],
            experience: scoresObject["Experience"],
          };
        });
        setData(list);
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [firestore, users]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    if (users.length > 0) {
      getCompletedSurveysData();
    }
  }, [getCompletedSurveysData, users]);

  return { isLoading, error, data, columns };
};

export { useGetCompletedSurveys };
