import styled from 'styled-components';
import { Link } from '@reach/router';
import { breakpoints } from '../../util/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  padding-bottom: 18px;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const Title = styled(Link)`
  color: #000;
  font-size: 18px;
  font-weight: bold;
  margin-left: 24px;
  text-decoration: none;
  display: none;

  @media (min-width: ${breakpoints.sm}px) {
    display: flex;
  }

  :hover {
    text-decoration: underline;
  }
`;

export { TopRow, Container, Title };
