import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 15,
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 450,
    marginTop: 10,
    marginBottom: 10
  },
});

export { StyledTableCell, useStyles };
