import React from 'react';
import AboutRow from './AboutRow';
import AboutSurveyRow from './AboutSurveyRow';
import MissionRow from './MissionRow';
import ProductsRow from './ProductsRow';
import { Container } from './home.styles';

const Home = () => (
  <Container>
    <MissionRow />
    <AboutSurveyRow />
    <AboutRow />
    <ProductsRow />
  </Container>
);

export default Home;