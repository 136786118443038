import styled from "styled-components";
import { colors } from "../../../util/theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Header = styled.p`
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: black;
  margin-bottom: 18px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6px 0;
  border: 1px solid ${colors.gray.light};
  width: 600px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${colors.gray.light};
  background-color: gray;
  color: white;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`;

const SubRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dotted ${colors.gray.light};
  margin: 0 16px;
  padding: 8px 0;
`;

const DateStyle = styled.p`
  font-size: 14px;
  color: black;
  margin: 0;
`;

const ScoreSpan = styled.span`
  font-weight: bold;
  margin: 0 6px;
`;

const SurveyCode = styled.p`
  font-size: 14px;
  color: black;
  margin: 0;
  text-transform: uppercase;
`;

const SubItemText = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: black;
  margin: 0;
`;
const ItemText = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: white;
  margin: 0;
`;

const YourScoreSuggestsText = styled.div`
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: black;
  margin: 16px 0;
`;

const YourScoreBreakdownText = styled.div`
  font-size: 14px;
  color: black;
  text-align: justify;
  line-height: 21px;
  padding: 8px 12px;
`;

export {
  Wrapper,
  Header,
  Container,
  Row,
  DateStyle,
  ItemText,
  SubItemText,
  ScoreSpan,
  SurveyCode,
  SubRow,
  YourScoreSuggestsText,
  YourScoreBreakdownText,
};
