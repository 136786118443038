export const ROUTES = {
  root: "/",
  welcome: "/welcome",
  about: "/about",
  contact: "/contact",
  products: "/products",
  assessment: "/assessment",
  recommendation: "/recommendation",
  register: "/register",
  profile: "/profile",
  login: "/login",
  passwordReset: "/reset-password",
};
