const ZIPCODE_API_KEY =
  "rEgsEAgYiuxh0GOhnplcfUxYiyL0NPJUb37EyiW8qU2RWXajjaNGHQx7ZrlCbrZz";
export const PWD_VALIDATION_TEXT = "Password must be at least 6 characters";

const USERNAME_REGEX = /^[a-zA-Z0-9_-]*$/;
export const USERNAME_VALIDATION_TEXT =
  "Username can only contain letters, numbers and underscores.";

export const validatePassword = (str) => {
  return str.length < 6 ? PWD_VALIDATION_TEXT : "";
};

export const validateUsername = (str) => {
  return !USERNAME_REGEX.test(str) ? USERNAME_VALIDATION_TEXT : "";
};

export const getPlaceDataByZip = async (zipcode) => {
  const url = `https://www.zipcodeapi.com/rest/${ZIPCODE_API_KEY}/info.json/${zipcode}/radians`;
  try {
    const response = await fetch(url);
    const responseJson = await response.json();
    const { city = "", state = "", zip_code: zipCode = "" } = responseJson;
    return { city, state, zipCode };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const formatDateFromTimestamp = (firestoreDate) => {
  const date = new Date(
    firestoreDate.seconds * 1000 + firestoreDate.nanoseconds / 1000000
  );
  return date.toDateString();
};

export const formatReadableDateFromTimestamp = (firestoreDate) => {
  if (!firestoreDate) return null;

  const options = { year: "numeric", month: "long", day: "numeric" };

  const date = new Date(
    firestoreDate.seconds * 1000 + firestoreDate.nanoseconds / 1000000
  );
  return date.toLocaleDateString("en-US", options);
};
