import styled from 'styled-components';
import { colors } from '../../../util/theme';

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0 48px 0;
  margin: 8px 24px;
`;

const Paragraph = styled.h3`
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
`;

const SubText = styled.p`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin: 4px 0;
`;

const HorizontalRule = styled.div`
  height: 1px;
  width: 100%;
  margin: 24px 0;
  background-color: ${colors.logo.yellow};
`;

export { Container, Paragraph, HorizontalRule, SubText };
