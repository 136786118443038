import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import Loading from "../Loading";
import {
  getCategoryScores,
  getOverallScore,
  getUnderstandingText,
} from "../../../util/scoreUtil";
import { formatDateFromTimestamp } from "../../../util/util";
import {
  Wrapper,
  Header,
  Container,
  Row,
  ItemText,
  SubItemText,
  SubRow,
  ScoreSpan,
  YourScoreBreakdownText,
  YourScoreSuggestsText,
} from "./surveyHistory.styles";

const SurveyHistory = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebase.auth);

  const getHistoryData = useCallback(
    async (uid) => {
      setLoading(true);
      const userScoresRef = firestore.collection("userScores");
      const query = await userScoresRef.where("userId", "==", uid).get();
      const scores = query.docs.map((doc) => doc.data());
      setData(scores || []);
      setLoading(false);
    },
    [firestore]
  );

  useEffect(() => {
    const { uid } = auth;
    // get assessmentData
    if (uid) getHistoryData(uid);
  }, [auth, getHistoryData]);

  return (
    <Wrapper>
      <Header>Assessment History</Header>
      {loading ? (
        <Loading />
      ) : (
        data.map((x) => {
          const categoryScores = x.scores;
          return (
            <Container key={x.createdAt.seconds}>
              <Row>
                <ItemText>{formatDateFromTimestamp(x.createdAt)}</ItemText>
                <ItemText>{x.surveyCode}</ItemText>
                <ItemText>
                  Total Score: <ScoreSpan>{x.totalScore}</ScoreSpan>
                </ItemText>
              </Row>
              {Object.entries(getCategoryScores(categoryScores)).map(
                ([key, value]) => (
                  <SubRow key={key}>
                    <SubItemText>{key}</SubItemText>
                    <SubItemText>
                      Score: <ScoreSpan>{value}</ScoreSpan>
                    </SubItemText>
                  </SubRow>
                )
              )}
              <YourScoreSuggestsText>
                Your score suggests {getOverallScore(x.totalScore)}
              </YourScoreSuggestsText>
              <YourScoreBreakdownText>
                {getUnderstandingText(x.totalScore)}
              </YourScoreBreakdownText>
            </Container>
          );
        })
      )}
    </Wrapper>
  );
};

export default SurveyHistory;
