import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Wrapper, AdminContainer, AdminMenuItem } from "./admin.styles";
import { UsersSection } from "./UsersSection";
import { SurveyCodesSection } from "./SurveyCodesSection";
import { OrganizationsSection } from "./OrganizationsSection";
import { GenerateCodesSection } from "./GenerateCodesSection";
import { CompletedSurveysSection } from "./CompletedSurveysSection";
import { TransactionsSection } from "./TransactionsSection";

const ADMIN_MENU_ITEMS = [
  "Users",
  "Survey Codes",
  "Organizations",
  "Completed Surveys",
  "Generate Codes",
  "Transactions",
];

const AdminMenuBar = ({ selectedMenuItem, handleSelectedMenuItem }) => (
  <AdminContainer>
    {ADMIN_MENU_ITEMS.map((item) => (
      <AdminMenuItem
        key={item}
        isSelected={selectedMenuItem === item ? "true" : "false"}
        onClick={() => handleSelectedMenuItem(item)}
      >
        {item}
      </AdminMenuItem>
    ))}
  </AdminContainer>
);

AdminMenuBar.propTypes = {
  selectedMenuItem: PropTypes.string.isRequired,
};

const Administration = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(ADMIN_MENU_ITEMS[0]);
  const getAdminSection = useCallback(() => {
    if (selectedMenuItem === ADMIN_MENU_ITEMS[0]) {
      return <UsersSection />;
    }
    if (selectedMenuItem === ADMIN_MENU_ITEMS[1]) {
      return <SurveyCodesSection />;
    }
    if (selectedMenuItem === ADMIN_MENU_ITEMS[2]) {
      return <OrganizationsSection />;
    }
    if (selectedMenuItem === ADMIN_MENU_ITEMS[3]) {
      return <CompletedSurveysSection />;
    }
    if (selectedMenuItem === ADMIN_MENU_ITEMS[4]) {
      return <GenerateCodesSection />;
    }
    if (selectedMenuItem === ADMIN_MENU_ITEMS[5]) {
      return <TransactionsSection />;
    }
    return null;
  }, [selectedMenuItem]);
  return (
    <Wrapper>
      <AdminMenuBar
        selectedMenuItem={selectedMenuItem}
        handleSelectedMenuItem={setSelectedMenuItem}
      />
      {getAdminSection()}
    </Wrapper>
  );
};

export { Administration };
