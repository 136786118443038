import styled from "styled-components";

const AdminContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: 1px solid black;
`;

const AdminMenuItem = styled.div`
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.isSelected === "true" ? "#eb9f23" : "#000")};
  cursor: pointer;
  margin: 0 12px;
  text-decoration: ${(props) =>
    props.isSelected === "true" ? "underline" : "none"};

  :hover {
    color: #eb9f23;
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
`;

const Header = styled.p`
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: black;
  margin-bottom: 18px;
`;

export { Wrapper, Header, AdminContainer, AdminMenuItem };
