import { useNavigate } from "@reach/router";
import React, { useState } from "react";
import { useFirebaseUser } from "../../hooks/useFirebaseUser";
import { ROUTES } from "../../util/routes";
import { AlertDialog } from "../Common/AlertDialog/AlertDialog";
import Button from "../Common/Button";
import { BasicPayPalButton } from "../Common/PayPalButtons/BasicPayPalButton";
import {
  Container,
  ItemRow,
  ProductItem,
  Title,
  Description,
  Price,
  ListItemIcon,
  SubListItemIcon,
  ButtonContainer,
} from "./products.styles";

export const Products = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const navigate = useNavigate();
  const { isLoggedIn } = useFirebaseUser();

  const handleOnSuccess = (transactionText) => {
    setDialogText(transactionText);
    setOpenDialog(true);
  };

  return (
    <Container>
      <AlertDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        title={"Transaction Complete"}
        message={dialogText}
      />
      <ItemRow>
        <ProductItem>
          <Title>Web-based Assessment (Basic)</Title>
          <Description>
            <ListItemIcon />
            Includes multicultural resource list
          </Description>
          <Description>
            <ListItemIcon />2 time access to the SMILE Assessment
          </Description>
          <Description>
            <ListItemIcon />
            <strong>$159.00</strong>
          </Description>
          {isLoggedIn ? (
            <BasicPayPalButton
              amount={"159.00"}
              productType={"Web-based Assessment (Basic)"}
              onSuccess={handleOnSuccess}
            />
          ) : (
            <Price>$159.00 ($250 value)</Price>
          )}
        </ProductItem>
        <ProductItem>
          <Title>Web-based Assessment (Premium)</Title>
          <Description>
            <ListItemIcon />
            Includes 47 page e-book (PDF) with demographic information,
            rationale, research, theoretical framework, and multicultural
            resource List
          </Description>
          <Description>
            <ListItemIcon />2 time access to the SMILE Assessment
          </Description>
          <Description>
            <ListItemIcon />
            <strong>$249.00 ($500 value)</strong>
          </Description>
          {isLoggedIn ? (
            <BasicPayPalButton
              amount={"249.00"}
              productType={"Web-based Assessment (Premium)"}
              onSuccess={handleOnSuccess}
            />
          ) : (
            <Price>$249.00 ($500 value)</Price>
          )}
        </ProductItem>
      </ItemRow>
      <ItemRow>
        <ProductItem>
          <Title>Sustainability Resources</Title>
          <Description>
            <ListItemIcon />
            Complimentary virtual mentoring and coaching call
          </Description>
          <Description>
            <ListItemIcon />
            Private Facebook group
          </Description>

          <Description>
            <ListItemIcon />
            Subscription: Tinsley Institute Multicultural Book Club (4 - 6
            weeks)
          </Description>
          <Description>
            <ListItemIcon />
            Subscription: Tinsley Institute Master Classes (4 weeks)
          </Description>
          <Description>
            <SubListItemIcon />
            Exploring multiple perspectives
          </Description>
          <Description>
            <SubListItemIcon />
            Unpacking implicit bias
          </Description>
        </ProductItem>
        <ProductItem>
          <Title>Bulk Discounts Available</Title>
          <Description>
            <ListItemIcon />
            Contact us for more information
          </Description>
          <ButtonContainer>
            <Button
              label="Contact Us"
              alt={"true"}
              action={() => navigate(ROUTES.contact)}
            />
          </ButtonContainer>
        </ProductItem>
      </ItemRow>
    </Container>
  );
};

export default Products;
