import { useFirestore } from "react-redux-firebase";
import { useCallback, useEffect, useState } from "react";
import { useFirebaseUser } from "./useFirebaseUser";
import { formatReadableDateFromTimestamp } from "../util/util";

const columns = [
  {
    name: "code",
    label: "Survey Code",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: "Code Status",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "claimedAt",
    label: "Claimed Date",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "completedAt",
    label: "Completed Date",
    options: {
      filter: true,
      sort: true,
    },
  },
];

const options = {
  filterType: "checkbox",
  selectableRowsHeader: false,
  selectableRows: "none",
};

const useGetPurchasedSurveyCodes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const { auth } = useFirebaseUser();
  const firestore = useFirestore();

  const { uid: userId } = auth;

  const getPurchasedSurveyCodes = useCallback(() => {
    setIsLoading(true);

    const purchasedCodesRef = firestore.collection("surveyCodes");
    const query = purchasedCodesRef.where("userId", "==", userId);

    query
      .get()
      .then((data) => {
        const list = data.docs.map((doc) => ({
          code: doc.data().code,
          status: doc.data().status,
          completedAt: doc.data().completedAt
            ? formatReadableDateFromTimestamp(doc.data().completedAt)
            : "N/A",
          claimedAt: doc.data().claimedAt
            ? formatReadableDateFromTimestamp(doc.data().claimedAt)
            : "N/A",
        }));
        setData(list);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      })
      .finally(() => setIsLoading(false));
  }, [firestore, userId]);

  useEffect(() => {
    getPurchasedSurveyCodes();
  }, [getPurchasedSurveyCodes]);

  return { isLoading, error, data, columns, options };
};

export { useGetPurchasedSurveyCodes };
