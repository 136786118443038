import React from "react";
import {
  Container,
  Wrapper,
  Box,
  ItemRow,
  Header,
  Footer,
  Title,
} from "./recommendation.styles";

export const RecommendationPage = () => (
  <Container>
    <Wrapper>
      <Title>Sustainability Resources</Title>
      <Box>
        <Header>Recommendation for every level</Header>
        <ItemRow>
          Participate in multi-cultural, multi-ethnic gatherings, activities,
          cultural events and community programs.
        </ItemRow>
        <ItemRow>
          These activities can be done via virtual or in person platforms with
          diverse populations in professional or social settings.
        </ItemRow>
      </Box>
      <Box>
        <Header>Recommendation for Poor rating</Header>
        <ItemRow>Complimentary Weekly coaching and mentoring call</ItemRow>
        <ItemRow>Join Private Facebook Group</ItemRow>
        <ItemRow>
          Subscribe to Tinsley Institute Multicultural Series: Exploring
          Multiple Perspectives
        </ItemRow>
        <ItemRow>Subscribe to Multicultural Book Club (Novice Level)</ItemRow>
      </Box>
      <Box>
        <Header>Recommendation for Minimal rating</Header>
        <ItemRow>Complimentary Weekly coaching and mentoring call</ItemRow>
        <ItemRow>Join Private Facebook Group</ItemRow>
        <ItemRow>
          Subscribe to Tinsley Institute Multicultural Series: Unpacking
          Implicit Bias
        </ItemRow>
        <ItemRow>Subscribe to Multicultural Book Club (Novice)</ItemRow>
      </Box>
      <Box>
        <Header>Recommendation for Satisfactory rating</Header>
        <ItemRow>Complimentary Weekly coaching and mentoring call</ItemRow>
        <ItemRow>Join Private Facebook Group</ItemRow>
        <ItemRow>
          Subscribe to Tinsley Institute Multicultural Series: Exploring
          Multiple Perspectives
        </ItemRow>
        <ItemRow>
          Subscribe to Multicultural Book Club (Novice or Advanced Level)
        </ItemRow>
      </Box>
      <Box>
        <Header>Recommendation for Strong rating</Header>
        <ItemRow>Complimentary Weekly coaching and mentoring call</ItemRow>
        <ItemRow>Join Private Facebook Group</ItemRow>
        <ItemRow>Subscribe to Tinsley Institute Master Class</ItemRow>
        <ItemRow>Subscribe to Multicultural Book Club (Advanced Level)</ItemRow>
      </Box>

      <Footer>
        Becoming Culturally Competent is a journey that unfolds over time...{" "}
        <br />
        The SMILE is a tool to guide you along!
      </Footer>
    </Wrapper>
  </Container>
);

export default RecommendationPage;
