import { useState } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";

const PAYMENT_SOURCE = "PayPal";

const useSetPaymentTransaction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const firestore = useFirestore();
  const firebase = useFirebase();

  const insertPaymentTransaction = async (paymentData) => {
    setIsLoading(true);

    await firestore.collection("paymentTransactions").add({
      ...paymentData,
      paymentSource: PAYMENT_SOURCE,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    setIsLoading(false);
  };

  return { isLoading, insertPaymentTransaction };
};

export { useSetPaymentTransaction };
