import React from "react";
import MUIDataTable from "mui-datatables";
import Loading from "../Loading";
import { useGetPaymentTransactions } from "../../../hooks/useGetPaymentTransactions";

const TransactionsSection = () => {
  const { data, isLoading, columns, options } = useGetPaymentTransactions();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MUIDataTable
      title={"Payment Transactions"}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export { TransactionsSection };
