import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Question from '../../Question';
import Button from '../../../Common/Button';
import { ButtonContainer, ModuleContainer, ModuleTitle } from '../../assessment.styles';

export const Category = ({ category, nextAction, isFinalCategory }) => {
  const [questions, setQuestions] = useState([]);
  const [moduleTitle, setModuleTitle] = useState('');
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (category.id) {
      const { name, questions } = category;
      setQuestions(questions);
      setModuleTitle(name);
      setAnswers([])
    }
  }, [category]);

  const handleAnswer = payload => {
    let newAnswers;
    const { questionId, score } = payload;
    const found = answers.find(x => x.questionId === questionId);
    if (found) {
      newAnswers = [ ...answers.filter(x => x.questionId !== questionId), { questionId, score }];
    } else {
      newAnswers = [ ...answers, { questionId, score }];
    }
    setAnswers(newAnswers);
  };

  const isModuleAnswered = answers.length >= questions.length;

  return (
    <>
      <ModuleContainer>
        <ModuleTitle>{moduleTitle}</ModuleTitle>
        {questions.map((x, indx) => (
          <Question
            key={x.id}
            question={x}
            count={indx+1}
            handleAnswer={handleAnswer}
          />
        ))}
      </ModuleContainer>
      <ButtonContainer>
        <Button 
          label={isFinalCategory ? "Complete" : "Next"}
          action={() => nextAction(answers)}
          disabled={!isModuleAnswered}
        />
      </ButtonContainer>
    </>
  )
}

Category.propTypes = {
  category: PropTypes.object.isRequired, 
  nextAction: PropTypes.func.isRequired, 
  isFinalCategory: PropTypes.bool.isRequired
};

export default Category;