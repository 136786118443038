import styled from 'styled-components';
import { breakpoints, colors } from '../../../util/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.alternate ? colors.gray.background : '#fff'};
  margin: 24px 0;

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
  }
`;

export {
  Container,
  Row,
};
