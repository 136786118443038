import React from 'react';
import {
  Container, 
  LeftColumn,
  RightColumn,
  Title,
  ListItem,
  ListItemIcon
} from './About.styles';

const About = () => (
  <Container>
    <LeftColumn />
    <RightColumn>
      <Title>Dr. Helen Tinsley</Title>
      <ListItem>
        <ListItemIcon />CEO of Tinsley Institute for Multicultural Empowerment, LLC.
      </ListItem>
      <ListItem>
      <ListItemIcon /> 30+ year experience in public, non-profit, private and faith based settings
      </ListItem>
      <ListItem>
      <ListItemIcon /> Workshop trainer &amp; facilitator: Diversity, Equity &amp; Inclusion, Implicit Bias and Second Language Learning
      </ListItem>
      <ListItem>
      <ListItemIcon /> Ed.D. – Rowan University; Educational Leadership
      </ListItem>
      <ListItem>
      <ListItemIcon /> M.S.Ed – Bank Street College of Education; Educational Leadership
      </ListItem>
      <ListItem>
      <ListItemIcon /> M.S. – Bank Street College of Education; Early Childhood &amp; Elementary Education
      </ListItem>
    </RightColumn>
  </Container>
  );

export default About;
