import styled from "styled-components";
import { colors } from "../../../../util/theme";

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0 48px 0;
  margin: 8px 24px;
  width: 700px;
`;

const Paragraph = styled.h3`
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
`;

const SubText = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin: 4px 0;
  text-align: center;
`;

const HorizontalRule = styled.div`
  height: 1px;
  width: 100%;
  margin: 24px 0;
  background-color: ${colors.logo.yellow};
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px dotted green;
  border-radius: 8px;
  padding: 0 24px;
  margin: 25px 0;
`;

const Results = styled.p`
  font-weight: bold;
  font-size: 32px;
  color: green;
  margin: 10px 0 10px 0;
  align-self: center;
`;
const ResultsText = styled.div`
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: black;
  margin: 16px 0;
`;

const Link = styled.span`
  color: ${colors.logo.red};
  cursor: pointer;
  text-decoration: underline;
`;

const UnderstandingText = styled.div`
  font-size: 16px;
  color: black;
  text-align: justify;
  line-height: 21px;
  padding: 8px;
`;

const SubRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px dotted ${colors.gray.light};
  margin: 0 16px;
  padding: 8px 0;
`;

const SubItemText = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: black;
  margin: 0;
`;

export {
  Container,
  Paragraph,
  HorizontalRule,
  Results,
  ResultsText,
  UnderstandingText,
  ResultsWrapper,
  SubText,
  Link,
  SubRow,
  SubItemText,
};
