import MUIDataTable from "mui-datatables";
import React from "react";
import { useGetOrganizations } from "../../../hooks/useGetOrganizations";
import Loading from "../Loading";

const OrganizationsSection = () => {
  const { isLoading, data, columns, options } = useGetOrganizations();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MUIDataTable
      title={"Organizations"}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export { OrganizationsSection };
