import styled from "styled-components";

const ProgressBarWrapper = styled.div`
  width: 95%;
  height: 18px;
  border: 1px solid #a30000;
  border-radius: ${(props) =>
    props.currentProgress === 1 ? "0" : "0 8px 8px 0"};
  margin: 0 24px 24px 24px;
`;

const ProgressIndicator = styled.div`
  height: 18px;
  border-radius: ${(props) =>
    props.currentProgress === 1 ? "0" : "0 8px 8px 0"};
  width: ${(props) => (props.currentProgress ? props.currentProgress : "1%")};
  background: #a30000;
  /* transition: width 500ms linear; */
`;

const ProgressText = styled.div`
  margin: 6px 0;
  font-size: 14px;
  font-weight: 500;
`;

export { ProgressBarWrapper, ProgressIndicator, ProgressText };
