import { categories } from "../assessmentData/categories";

// Overall Score:
// 300 points suggests a person who has strong cultural competence.
// 240 points suggests a person who has satisfactory cultural competence
// 180 points suggests a person who is developing cultural competence.
// 120 points suggests a person who has limited cultural competence.
// 60 points suggests a person who has little to no cultural competence.

// Individual Component Scores:
// 75 points in any one component suggests a strong level of cultural competence in that area.
// 60 points in any one component suggests a satisfactory level of cultural competence in that area.
// 45 points in any one component suggest a beginning level cultural competence in that area.
// 30 points in any one component suggests a limited level of cultural competence in that area.
// 15 points in any one component suggests no indication of cultural competence in that area.

export const getScore = (scores) => {
  return Object.values(scores).reduce((acc, item) => {
    acc = acc + item.score;
    return acc;
  }, 0);
};

export const getOverallScore = (score) => {
  if (score <= 60) {
    return "you have little to no cultural competence.";
  } else if (score <= 120) {
    return "you have limited cultural competence.";
  } else if (score <= 180) {
    return "you are developing cultural competence.";
  } else if (score <= 240) {
    return "you have satisfactory cultural competence.";
  } else {
    return "you have strong cultural competence.";
  }
};

export const getCategoryScores = (scores) => {
  let result = categories.reduce((acc, item) => {
    acc = { ...acc, [item.name]: 0 };
    return acc;
  }, {});

  scores.forEach((score) => {
    const category = categories.find(
      (x) => x.id.toString() === score.questionId.toString().substring(0, 1)
    );
    const scoreValue = result[category.name];
    result[category.name] = score.score + scoreValue;
  });

  return result;
};

const POOR_RATING_TEXT =
  "A rating of poor suggests an individual might have biased views, negative assumptions and limited experiences with diverse populations, across race, culture, language, gender and sexual orientation. People that score in this range  may tend to have little knowledge about White privilege, institutional racism, systemic oppression and gender bias. This could result in a lack of multiracial and multicultural awareness, sensitivity to diverse populations and insufficient ability to effectively interact and engage with people from different backgrounds and cultures in a variety of situations and settings.";
const MINIMAL_RATING_TEXT =
  "A rating of minimal suggests an individual might have conservative beliefs, misperceptions and inaccurate judgments about people from diverse populations and backgrounds. They may have had few experiences with people from different backgrounds and little knowledge of their obscured stories and histories. People that score in this range  tend to have limited knowledge about the roots of oppression, and the institutional practices and policies that continue to discriminate against traditionally marginalized people. This could result in communication difficulties, shallow relationships with diverse populations on professional or social levels, and superficial relationships with diverse people on personal levels. ";
const SATISFACTORY_RATING_TEXT =
  "A rating of satisfactory suggests an individual might have open-minded beliefs, contemporary views, and knowledge about diverse cultures, experiences and stories. These people may be aware of systemic oppression and how it impacts different populations today.  People that score in this range tend to have adequate beliefs, knowledge, skills and experience to engage with diverse groups in different contexts. However, they may still be more comfortable in their interactions with specific populations and less comfortable with others.";
const STRONG_RATING_TEXT =
  "A rating of strong suggests an individual might be non-judgmental, receptive, multiculturally conscious, culturally savvy and objective in their relationships with diverse populations. People that score in this range may have had a variety of experiences with diverse groups in different settings and situations. They tend to be very comfortable engaging and interacting with people across all levels of diversity in  different contexts on personal, professional and societal levels.";

export const getUnderstandingText = (score) => {
  if (score > 240) {
    return STRONG_RATING_TEXT;
  } else if (score > 180) {
    return SATISFACTORY_RATING_TEXT;
  } else if (score > 120) {
    return MINIMAL_RATING_TEXT;
  } else {
    return POOR_RATING_TEXT;
  }
};
