import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../util/theme";
import { ROUTES } from "../../util/routes";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin: 0 12px;
  cursor: pointer;
`;
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;
`;
const Bar1 = styled.div`
  height: 14px;
  width: 115px;
  background-color: #f0a91d;
`;
const Bar2 = styled.div`
  height: 15px;
  background-color: #a30000;
`;
const Bar3 = styled.div`
  height: 15px;
  background-color: black;
`;
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #757989;

  @media (min-width: ${breakpoints.sm}px) {
    font-size: 18px;
  }
`;

const Logo = () => (
  <Container to={ROUTES.root}>
    <LeftColumn>
      <Bar1 />
      <Bar2 />
      <Bar3 />
    </LeftColumn>
    <RightColumn>
      <Title>Survey Of</Title>
      <Title>Multicultural Interactions &amp;</Title>
      <Title>Linguistic Engagement</Title>
    </RightColumn>
  </Container>
);

export default Logo;
