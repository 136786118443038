import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 12px 42px;
  margin: 24px auto;
  width: 450px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin: 12px 0;
  text-align: center;
`;

const SubText = styled.p`
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  padding: 0;
  margin: 8px 0;
  color: #000;
`;

const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 0;
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin-right: 24px;
  color: gray;
  width: 100px;
  text-align: end;
`;

const Element = styled.div`
  margin: 8px 0;
  width: 100%;
`;

const Title = styled.h3`
  font-weight: bold;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  align-self: center;
`;

const LinkStyle = styled.span`
  color: ${(props) => (props.alt ? "blue" : "#f0a91d")};
  margin-top: 8px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const Link = styled.a`
  color: #ff6738;
  margin-top: 8px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextStyle = styled.p`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin: 0;
`;

const PasswordHelp = styled.p`
  font-size: 12px;
  color: gray;
  padding: 0;
  margin: 4px 8px;
`;

const EmailSentMessage = styled.p`
  font-size: 14px;
  color: green;
  text-align: center;
`;

export {
  Container,
  ItemRow,
  Label,
  FormContainer,
  Element,
  ButtonWrapper,
  Header,
  LinkStyle,
  SubText,
  Title,
  Link,
  Section,
  TextStyle,
  PasswordHelp,
  EmailSentMessage,
};
