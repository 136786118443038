import React from "react";
import PropTypes from "prop-types";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useFirebaseUser } from "../../../hooks/useFirebaseUser";
import { useSetPaymentTransaction } from "../../../hooks/useSetPaymentTransaction";

const BasicPayPalButton = ({ amount, productType, onSuccess }) => {
  const { auth, user } = useFirebaseUser();
  const { insertPaymentTransaction } = useSetPaymentTransaction();

  const { uid: userId } = auth;
  const { email: userEmail } = user;

  return (
    <PayPalButtons
      amount={amount}
      onError={(error) => console.log("PayPal error", error)}
      createOrder={async (_, actions) => {
        // Set up the transaction
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: amount,
              },
              shipping: {
                options: [
                  {
                    id: "000001",
                    label: "SMILE survey code will be e-mailed to payer",
                    selected: true,
                  },
                ],
              },
            },
          ],
        });
      }}
      onApprove={async (data, actions) => {
        // This function captures the funds from the transaction.
        return actions.order.capture().then(async (details) => {
          const paymentData = {
            paymentEmail: details.payer.email_address,
            userEmail,
            userId,
            orderId: data.orderID,
            firstName: details.payer.name.given_name,
            lastName: details.payer.name.surname,
            payerId: details.payer.payer_id,
            paymentAmount: amount,
            productType,
          };

          await insertPaymentTransaction(paymentData);

          const transactionText = `
          Your PayPal transaction was completed by ${details.payer.name.given_name} ${details.payer.name.surname}.
          An e-mail has been sent with your survey codes!`;

          onSuccess(transactionText);
        });
      }}
    />
  );
};

BasicPayPalButton.propTypes = {
  amount: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export { BasicPayPalButton };
