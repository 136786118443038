import styled from "styled-components";
import { colors } from "../../util/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 24px;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 625px;
  margin: 24px auto;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin: 12px 0;
  border: 1px solid ${colors.gray.light};
  border-radius: 8px;
`;

export const Header = styled.h3`
  color: ${colors.logo.red};
  font-size: 18px;
  font-weight: 600;
  margin: 12px;
  text-align: left;
  text-transform: capitalize;
`;
export const Title = styled.h1`
  color: ${colors.black};
  font-size: 18px;
  font-weight: 600;
  margin: 12px;
  text-align: center;
  text-transform: capitalize;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 24px;
  border-bottom: 1px solid ${colors.gray.light};
`;

export const Footer = styled.div`
  color: gray;
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  margin: 42px 0;
`;
