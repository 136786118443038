import React from "react";
import MUIDataTable from "mui-datatables";
import Loading from "../Loading";
import { useGetPurchasedSurveyCodes } from "../../../hooks/useGetPurchasedSurveyCodes";

const PurchasedSurveyCodes = () => {
  const { data, isLoading, columns, options } = useGetPurchasedSurveyCodes();

  console.log({ data });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MUIDataTable
      title={"Your Survey Codes"}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export { PurchasedSurveyCodes };
