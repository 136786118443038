import styled from "styled-components";
import { colors } from "../../util/theme";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: white;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 170px;
  width: 220px;
  height: 500px;
  border-right: 1px solid gray;
`;

const MenuHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: black;
  padding: 12px;
  margin-bottom: 12px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  min-width: 350px;
  height: 100%;
  border-top: 1px solid black;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 12px;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => (props.selected ? colors.logo.red : "black")};
  text-decoration: ${(props) => (props.selected ? "underline" : "none")};

  :hover {
    color: ${colors.logo.yellow};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export { Wrapper, MenuWrapper, MenuHeader, MenuItem, ContentWrapper };
