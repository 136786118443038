import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useFirestore,
  useFirebase,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { Category, Completed } from "./Modules";
import ProgressBar from "../Common/ProgressBar";
import ExplanationOfTerms from "../Common/ExplanationOfTerms";
import {
  CLEAR_USER_SCORES,
  SET_USER_SCORES,
} from "../../redux/actions/actions";
import { calculateProgress } from "../Common/ProgressBar/helper";
import { Container } from "./assessment.styles";
import { getScore } from "../../util/scoreUtil";
import NotAuthorized from "./NotAuthorized";
import assessmentData from "../../assessmentData/fullAssessment";

export const Assessment = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [userScores, setUserScores] = useState([]);

  const dispatch = useDispatch();
  const firestore = useFirestore();
  const firebase = useFirebase();

  const auth = useSelector((state) => state.firebase.auth);
  const user = useSelector((state) => state.firebase.profile);
  const code = useSelector((state) => state.userData.surveyCode);
  const surveyCode = `${code}`.toUpperCase();

  const isNotAuthorizedForAssessment =
    (isLoaded(auth) && isEmpty(auth)) || !surveyCode;

  const nextAction = async (data) => {
    setCurrentStep(currentStep + 1);
    const overallScore = [...userScores, ...data];
    setUserScores(overallScore);

    // save score progress in application state
    dispatch({ type: SET_USER_SCORES, payload: { surveyCode, scores: data } });

    // scroll to the top of page
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (isFinalCategory) {
      // send scores and totalScore to database on complete...
      const totalScore = getScore(overallScore);
      await saveUserScoreToCollection(overallScore, totalScore);
      dispatch({ type: CLEAR_USER_SCORES });

      // update survey code to COMPLETED with date
      const surveyCodeRef = firestore.collection("surveyCodes");
      await surveyCodeRef.doc(surveyCode).update({
        status: "COMPLETED",
        completedAt: firestore.FieldValue.serverTimestamp(),
      });
    }
  };

  const saveUserScoreToCollection = async (overallScore, totalScore) => {
    const userScoresRef = firestore.collection("userScores");
    const snapshot = await userScoresRef.get();
    if (!snapshot.exists) {
      const { uid } = auth;
      const payload = {
        userId: uid,
        email: user.email,
        displayName: user.displayName,
        surveyCode,
        scores: overallScore,
        totalScore,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      await userScoresRef.add(payload);
    }
  };

  const isFinalCategory = assessmentData.length === currentStep;

  const getModule = () => {
    if (currentStep <= assessmentData.length) {
      return (
        <Category
          nextAction={nextAction}
          isFinalCategory={isFinalCategory}
          currentStep={currentStep}
          category={assessmentData.find((x) => x.id === currentStep)}
        />
      );
    } else {
      return <Completed scores={userScores} />;
    }
  };
  const currentProgress = calculateProgress(currentStep);

  if (isNotAuthorizedForAssessment) {
    console.log("User NOT authenticated to take assessment!");
    return <NotAuthorized />;
  }

  return (
    <>
      <ExplanationOfTerms />
      <Container>
        {currentStep <= assessmentData.length && (
          <ProgressBar currentProgress={currentProgress} />
        )}
        {getModule()}
      </Container>
    </>
  );
};

export default Assessment;
